/* eslint-disable camelcase */
export const es = {
  HelpDocs: {
    contractTitle: "Contratos",
    contractName1: "Contrato Psicológico 2024",
    contractName2: "Contrato Psicológico RU 2024",
    contractName3: "Contrato Psicológico UA 2024",
    contractName4: "Política de Protección de Datos Personales - Cláusula Informativa 2024",
    handbookTitle: "Manual y Catálogo de Pruebas",
    handbookName1: "Características de Áreas de Diagnóstico",
    handbookName2: "Pruebas Psicomotoras",
    handbookName3: "Pruebas Cognitivas",
    handbookName4: "Pruebas de Personalidad",
    handbookName5: "Catálogo de Pruebas 03-2024",
    modulesTitle: "Módulos de Entrevista",
    modulesName1: "Situación de Salud",
    modulesName2: "Situación Social y Familiar",
    modulesName3: "Observación",
    modulesName4: "Detectives Protección Armas MTD I",
    modulesName5: "Licencia MTD II",
    modulesName6: "Medicina Ocupacional MTD III",
    modulesName7: "Conductores de Servicio MTD III",
    modulesName8: "Trabajo en Altura MTD III",
    modulesName9: "Montacargas MTD III",
    modulesName10: "Psicología del Transporte Operación de Vehículos MTD IV"
  },
  Format: {
    ViewDateTime: "DD.MM.YYYY HH:mm",
    FormDateFormat: "D MMMM YYYY",
    ExamineeViewDateFormat: "DD.MM.YYYY",
  },
  Buttons: {
    accept: "Acepto",
    addExaminee: "Agregar examinado",
    addPsychLab: "Agregar cuenta de cliente",
    addPsychUser: "Agregar cuenta de usuario",
    addUser: "Agregar cuenta de usuario",
    addDirectory: "Agregar carpeta",
    assign: "Asignar",
    alreadyAssigned: "Asignado",
    assignExamination: "Asignar examen",
    alreadyAssignedExamination: "Examen en curso",
    back: "Volver",
    backToLogin: "Volver a la página de inicio de sesión",
    begin: "Comenzar",
    cancel: "Cancelar",
    change: "Cambiar",
    clear: "Limpiar",
    close: "Cerrar",
    confirm: "Confirmar",
    continue: "Continuar",
    createExamination: "Asignar examen",
    delete: "Eliminar",
    deleteExaminee: "Eliminar examinado",
    deletePsychLab: "Eliminar cuenta de cliente",
    deletePsychLabLic: "Eliminar licencia",
    downloadAll: "Descargar todo",
    edit: "Editar",
    editExaminee: "Editar examinado",
    editPsychLab: "Editar cuenta de cliente",
    editPsychLabLic: "Editar licencia",
    addPsychLabLic: "Agregar licencia",
    editPsychLabUser: "Editar cuenta de usuario",
    examinationWithNoLawBase: "Examen sin base legal",
    examinationWithLawBase: "Examen con base legal",
    exit: "Salir",
    generate: "Generar",
    generateAndPrint: "Generar e imprimir",
    go: "Ir",
    goToDashboard: "Ir al panel de usuario",
    login: "Iniciar sesión",
    loginPsychLab: "Iniciar sesión en cuenta de cliente",
    move: "Mover",
    moveExaminee: "Mover examinado",
    moveExaminees: "Mover examinados",
    ok: "Aceptar",
    print: "Imprimir",
    printContract: "Imprimir contrato",
    save: "Guardar",
    savePassword: "Guardar contraseña",
    savePin: "Guardar PIN",
    saveAndPrint: "Guardar e imprimir",
    saveSettings: "Guardar configuración",
    setPin: "Establecer PIN",
    search: "Buscar",
    setPassword: "Establecer contraseña",
    setNewPassword: "Establecer nueva contraseña",
    sendLink: "Enviar enlace",
    skipTests: "Omitir pruebas",
    understood: "Entendido",
    showActivities: "Mostrar",
    exportActivities: "Exportar a archivo XLS",
  },
  Common: {
    menu: {
      contact: "Ayuda",
      dashboard: "Panel de usuario",
      folders: "Registros de examinados",
      search: "Buscar",
      logout: "Cerrar sesión",
      settings: "Configuración",
      psychlabs: "Registros de clientes",
      breadcrumbsPsychLab: "Registros de clientes",
    },
    seo: {
      addExaminee: "Añadir examinado",
      addPsychLabLic: "Añadir licencia",
      addPsychLab: "Añadir cuenta de cliente",
      dashboard: "Panel de usuario",
      editExaminee: "Editar examinado",
      helpdesk: "Ayuda",
      helpdeskDocuments: "Documentos de ayuda",
      judgement: "Dictamen",
      login: "Iniciar sesión",
      onboarding: "Incorporación",
      report: "Informe",
      resetPassword: "Restablecer contraseña",
      setNewPassword: "Establecer nueva contraseña",
      search: "Buscador",
      folders: "Archivo de examinados",
      settings: "Ajustes",
      examinationAssignment: "Asignación de examen con base legal",
      examinationCard: "Tarjeta de Examen"
    },
    warningPopup: {
      text: "Los cambios realizados no se guardarán",
      header: "¿Estás seguro que deseas salir?",
      textSave: "Los campos requeridos también pueden estar en otras pestañas",
      headerSave: "¡Por favor, completa todos los campos requeridos!",
      promptLogout: "Se cerrará la sesión automáticamente en PROMPT_TIME_MINUTES minuto por inactividad."
    },
    logAdminAsUserPopup: {
      text: "No se pudo iniciar sesión en la cuenta del cliente",
      header: "Error"
    },
    deleteExamineePopup: {
      text: "Después de eliminar al examinado, no será posible restaurar los datos",
      header: "¿Estás seguro que deseas eliminar al examinado?"
    },
  },
  Contact: {
    header: "Soporte técnico",
  },
  Cookies: {
    header: "Permiso para usar cookies",
    consent: "Este sitio utiliza cookies para asegurar el mejor rendimiento de esta aplicación. Si consientes almacenar información en cookies, haz clic en el botón ACEPTAR. Si no consientes, no podrás usar la aplicación ni realizar los procedimientos asignados."
  },
  Dashboard: {
    header: "Personas añadidas recientemente",
    footer: {
      lastLogin: "Último acceso",
      lastFailedLogin: "Último acceso fallido",
      passwordExpired: "Su contraseña expirará en",
      day: "dias",
      days: "dia",
    },
    emptyState: {
      header: "No hay examinados agregados recientemente",
      info: "Para ver la lista de examinados, agrega al menos uno.",
    },
    kebabMenu: {
      print: "Imprimir contrato",
      profile: "Ir al perfil del examinado",
    },
  },
  AdminDashboard: {
    header: "Registro de clientes",
    emptyState: {
      header: "Aún no se han agregado cuentas de clientes",
      info: "Para ver la lista de clientes, agregue al menos una cuenta."
    },
    emptyStateUsers: {
      header: "Aún no se han agregado cuentas de usuario",
      info: "Para ver la lista de usuarios, agregue al menos una cuenta de usuario."
    },
    emptyStateLicense: {
      header: "Aún no se han agregado licencias",
      info: "Para ver la licencia, agréguela."
    },
    kebabMenu: {
      print: "Imprimir contrato",
      profile: "Ir al perfil del examinado"
    },
    PsychologicalLabsList: {
      labels: {
        name: "Nombre del cliente",
        clientId: "ID del cliente",
        status: "Estado",
        psychCnt: "Número de usuarios",
        createDate: "Fecha de creación",
        expireDate: "Fecha de vencimiento de la cuenta",
        details: "Detalles",
        usersCnt: "Número de usuarios",
        examineesCnt: "Número de examinados",
        examinationsCnt: "Número de exámenes",
        taskPerPage: "Filas por página:",
        all: "todos",
        of: "de",
        moreThen: "más que"
      },
      labStatus: {
        enabled: "activo",
        disabled: "inactivo"
      }
    },
    Licence: {
      labels: {
        methodologyList: "Metodologías disponibles",
        testsList: "Pruebas disponibles",
        licenseDate: "Fecha de emisión de licencia",
        expireDate: "Fecha de vencimiento de licencia"
      },
      deletePopup: {
        headerText: "¿Está seguro de que desea eliminar la licencia?",
        contentText: "Después de eliminar la licencia, no será posible asignar exámenes",
        confirmationText: "Sí, quiero eliminar la licencia"
      }
    },
    PsychLab: {
      deletePopup: {
        headerText: "¿Está seguro de que desea eliminar la cuenta del cliente?",
        contentText: "Después de eliminar la cuenta del cliente, no será posible restaurar los datos",
        confirmationText: "Sí, quiero eliminar la cuenta del cliente"
      }
    },
    StatsList: {
      statFileName: "estadisticas_examenes.xlsx"
    },
    ActivitiesList: {
      labels: {
        date: "Fecha",
        action: "Acción",
        executor: "Ejecutor",
        context: "Descripción",
        status: "Estado",
        errorMsg: "Descripción del error"
      },
      labStatus: {
        success: "ok",
        error: "error"
      }
    },
    UsersList: {
      deletePopup: {
        headerText: "¿Está seguro de que desea eliminar la cuenta de usuario?",
        contentText: "Después de eliminar la cuenta de usuario, no será posible restaurarla",
        confirmationText: "Sí, quiero eliminar la cuenta de usuario"
      },
      labels: {
        firstName: "Nombre",
        lastName: "Apellido",
        email: "Correo electrónico",
        examineeCnt: "Número de participantes",
        examinationCnt: "Número de exámenes",
        isActive: "Estado",
        isStaff: "Rol"
      },
      labStatus: {
        enabled: "activo",
        disabled: "inactivo",
        adminRole: "administrador",
        superUserRole: "super administrador",
        userRole: "usuario",
        userBaseRole: "registrador"
      },
      toolTip: {
        edit: "editar",
        delete: "eliminar"
      }
    },
    PsychLabManagment: {
      breadcrumbs: {
        add: "Agregar cuenta de cliente",
        edit: "Editar cuenta de cliente",
        addLicense: "Agregar licencia",
        editLicense: "Editar licencia",
        addUser: "Agregar cuenta de usuario",
        editUser: "Editar cuenta de usuario",
        dashboard: "Panel de usuario",
        psychLabs: "Registro de clientes"
      },
      header: {
        add: "Agregar cuenta de cliente",
        edit: "Editar cuenta de cliente",
        addUser: "Agregar cuenta de usuario",
        editUser: "Editar cuenta de usuario",
        addLicense: "Agregar licencia",
        editLicense: "Editar licencia"
      },
      PsychLabView: {
        labels: {
          details: "Detalles",
          download: "Descargar",
          edit: "Editar",
          generate: "Generar",
          name: "Nombre de la prueba",
          nameFile: "Nombre del archivo",
          print: "Imprimir",
          result: "Resultado",
          type: "Tipo de documento"
        },
        tabs: {
          psychLabData: "Datos del cliente",
          license: "Licencia",
          users: "Usuarios",
          activityLogs: "Registro de actividades",
          activityLogsFile: "registro_actividades",
          activityId: "id",
          activityDate: "fecha",
          activityAction: "nombre de la acción",
          activityStatus: "estado",
          activityContext: "descripción",
          activityExecutor: "ejecutor",
          stats: "Estadísticas",
          theme: "Apariencia"
        }
      }
    }
  },
  ExaminationAssignmentView: {
    header: "Asignar la evaluación",
    labels: {
      chooseExaminationType: "Elegir tipo de examen",
      chooseLawBase: "Elegir base legal para el examen",
      description: "Descripción",
      legalBase: "Base legal para el examen",
      reason: "Propósito del examen",
      suggestedTestMethods: "METODOLOGÍAS SUGERIDAS",
      methodologyTest2Safe: "TEST2SAFE - metodología de pruebas psicológicas para personal de seguridad y detectives",
      methodologyTest2Drive: "TEST2DRIVE - metodología para realizar exámenes de conductores en psicología del transporte",
      methodologyTest2Arms: "TEST2ARMS - metodología de pruebas psicológicas, actividad con licencia",
      methodologyTest2Work: "TEST2WORK - metodología de pruebas psicológicas en medicina ocupacional"
    },
    popup: {
      header: "Algunas pruebas recomendadas para la metodología seleccionada han sido desactivadas.",
      content: "¿Está seguro de que desea omitir estas pruebas?",
      checkbox: "Recordar elección para todos los examinados"
    },
    license: "Prueba no disponible en la licencia adquirida"
  },
  PsychologicalTests: {
    psychomotor: "Pruebas psicomotoras",
    cognitive: "Pruebas cognitivas",
    personality: "Pruebas de personalidad",
  },
  ExamineeList: {
    labels: {
      assign: "Asignado",
      assignDate: "Fecha de asignación",
      create: "Creado",
      createDate: "Fecha de creación",
      contract: "Contrato",
      documentation: "Documentación",
      end: "Completado",
      endDate: "Fecha de finalización",
      interview: "Entrevista",
      identificator: "ID del examen",
      language: "Idioma",
      nameArchiveExamination: "Nombre de la batería",
      nameExamination: "Nombre del examen",
      start: "Iniciado",
      startDate: "Fecha de inicio"
    },
    kebabMenu: {
      delete: "Eliminar examen"
    }
  },
  ExaminationList: {
    withoutLegalBasis: "Examen sin base legal",
    labels: {
      assign: "Asignado",
      assignDate: "Fecha de asignación",
      create: "Creado",
      createDate: "Fecha de creación",
      contract: "Contrato",
      documentation: "Documentación",
      end: "Finalizado",
      endDate: "Fecha de finalización",
      interview: "Entrevista",
      identificator: "ID del examen",
      language: "Idioma",
      nameArchiveExamination: "Nombre de la batería",
      nameExamination: "Nombre del examen",
      start: "Iniciado",
      startDate: "Fecha de inicio",
    },
    kebabMenu: {
      delete: "Eliminar examen",
    },
    deletePopup: {
      headerText: "¿Está seguro de que desea eliminar el examen?",
      contentText: "Después de eliminar el examen, no será posible restaurarlo",
      confirmationText: "Sí, quiero eliminar el examen"
    },
    toolTip: {
      contract: "Generar contrato",
      interview: "Entrevista",
      documentation: "Documentación",
      delete: "Eliminar"
    },
  },
  ArchiveExaminationList: {
    emptyState: {
      info: "No hay evaluaciones archivadas asignadas a este participante",
    },
  },
  ExaminationView: {
    header: "Documentación",
    breadcrumbs: {
      archive: "Exámenes archivados"
    },
    details: {
      createDate: "Creación del examen",
      endDate: "Finalización del examen",
      identificator: "ID del examen",
      language: "Idioma",
      name: "Nombre del examen",
      startDate: "Fecha de inicio del examen",
      type: "Nombre de la batería"
    },
    protocols: {
      language: "Idioma de los protocolos",
      testProtocol: "Protocolo de prueba"
    },
    labels: {
      details: "Detalles",
      download: "Descargar",
      edit: "Editar",
      generate: "Generar",
      name: "Nombre de la prueba",
      nameFile: "Nombre del archivo",
      print: "Imprimir",
      result: "Resultado",
      type: "Tipo de documento"
    },
    tabs: {
      documentation: "Documentación",
      protocols: "Protocolos",
      reports: "Informes"
    },
    common: {
      unavailableInLanguageOption: "No disponible en el idioma seleccionado."
    },
    aimsKind: {
      aimKind_1: "Opinión psicológica - detectives",
      aimKind_3: "Certificado - trabajador de seguridad física",
      aimKind_8: "Certificado de apelación - trabajador de seguridad física",
      aimKind_9: "Certificado - permiso de armas",
      aimKind_10: "Certificado - actividad licenciada para fines militares o policiales",
      aimKind_11: "Certificado - actividad licenciada para fines civiles",
      aimKind_12: "Certificado de apelación - actividad licenciada para fines civiles",
      aimKind_13: "Certificado - medicina ocupacional",
      aimKind_14: "Certificado - medicina ocupacional minería y rescate minero",
      aimKind_15: "Certificado - medicina ocupacional licencia de maquinista",
      aimKind_16: "Certificado - conductor categoría C1, C1+E, C, C+E, D1, D1+E, D y D+E o conducción de tranvía",
      aimKind_17: "Certificado - restitución de licencia de conductor categoría AM, A1, A2, A, B1, B, B+E y T",
      aimKind_18: "Certificado - restitución de licencia de conductor contraindicaciones psicológicas",
      aimKind_19: "Certificado - extensión de licencia categoría C1, C1+E, C, C+E, D1, D1+E, D y D+E o conducción de tranvía",
      aimKind_20: "Certificado - examen post-accidente del conductor",
      aimKind_21: "Certificado - examen del conductor después del consumo de alcohol o sustancias similares",
      aimKind_22: "Certificado - examen del conductor después de exceder 24 puntos",
      aimKind_23: "Certificado - examen del conductor después de al menos 2 infracciones",
      aimKind_24: "Certificado - examen del conductor contraindicaciones psicológicas",
      aimKind_25: "Certificado - conductor de vehículo de emergencia",
      aimKind_26: "Certificado - examen post-accidente del conductor (referido por el starost)",
      aimKind_27: "Certificado - desempeño de funciones de instructor",
      aimKind_28: "Certificado - desempeño de funciones de examinador",
      aimKind_29: "Certificado - estudiante conductor o candidato para escuela secundaria categoría C o C+E",
      aimKind_30: "Certificado - estudiante conductor o candidato para curso vocacional categoría C o C+E",
      aimKind_31: "Certificado - conductor profesional referido por empleador",
      aimKind_32: "Certificado - conductor profesional autónomo",
    },
  },
  ExamineeView: {
    anonymity: "Sujeto anónimo",
    userData: {
      defaultAnonFirstName: "Sujeto",
      defaultAnonLastName: "Anónimo",
      pesel: "Número de identificación personal",
      sex: "Sexo",
      birthDate: "Fecha de nacimiento",
      address: "Dirección",
      education: "Educación",
      drivingLicense: "Categoría de permiso de conducir",
      identityCard: "Documento de identidad",
      serialNumber: "Número de serie",
      identificator: "Identificador",
      drivingLicenseIssuer: "Autoridad emisora del permiso de conducir",
      examineName: "Nombre y apellidos",
    },
    drivingLicense: {
      am: "AM",
      a1: "A1",
      a2: "A2",
      a: "A",
      b1: "B1",
      b: "B",
      be: "B+E",
      c: "C",
      c1: "C1",
      c1e: "C1+E",
      ce: "C+E",
      d: "D",
      d1: "D1",
      d1e: "D1+E",
      de: "D+E",
      t: "T",
      tranvia: "TRANVÍA"
    },
    tabs: {
      archive: "Exámenes archivados",
      data: "Datos del sujeto",
      examination: "Exámenes",
    },
    kebabMenu: {
      edit: "Editar datos del sujeto",
      delete: "Eliminar sujeto",
    },
    emptyState: {
      header: "No hay exámenes asignados a este sujeto",
      info: "Para ver la lista de exámenes, asigne al menos un examen.",
    },
    translations: {
      passport: "pasaporte",
      id_card: "documento de identidad",
      male: "hombre",
      female: "mujer",
      primary: "educación primaria",
      lowerSecondary: "educación secundaria básica",
      basicVocational: "formación profesional básica",
      basicIndustry: "formación técnica básica",
      secondaryIndustry: "formación técnica media",
      secondary: "educación secundaria",
      higher: "educación superior",
    },
  },
  ExamineeManagment: {
    breadcrumbs: {
      add: "Agregar sujeto",
      edit: "Editar sujeto",
      dashboard: "Panel de usuario",
      directories: "Registro de sujetos",
    },
    header: {
      add: "Agregar nuevo sujeto",
      edit: "Editar datos del sujeto",
    },
  },
  Forms: {
    adminFields: {
      methodologyTlt: "Metodología",
      testPsychMotorTlt: "pruebas psicomotoras",
      testCogTlt: "pruebas cognitivas",
      testPersonTlt: "pruebas de personalidad",
      address: "Calle y número",
      name: "Nombre",
      firstName: "Nombre",
      lastName: "Apellidos",
      email: "Correo electrónico",
      password: "Contraseña",
      pin: "Código PIN",
      isActive: "Estado de la cuenta",
      isStaff: "Rol",
      dateExpire: "Fecha de vencimiento de la cuenta",
      dateLicExpire: "Fecha de vencimiento de la licencia",
      licMethodologies: "Metodologías disponibles",
      licTests: "Pruebas disponibles",
      city: "Ciudad",
      postalCode: "código postal",
      clientId: "ID del cliente",
      cin: "Número de registro",
      tin: "NIF/CIF",
      theme: "Apariencia",
      examineesCnt: "Número de sujetos",
      usersCnt: "Número de usuarios",
      dateFrom: "rango desde",
      dateTo: "rango hasta",
      activityName: "actividad",
      activityDetails: "Detalles",
      status: "estado",
    },
    adminOptions: {
      theme: {
        optimisTheme: "optimis",
        defaultTheme: "predeterminado",
      },
      status: {
        active: "activo",
        disabled: "inactivo",
      },
      admin: {
        admin: "administrador",
        user: "usuario",
        baseuser: "registrador",
      },
    },
    adminSnackbar: {
      required: "Por favor, complete todos los campos obligatorios.",
      email_unique_error: "¡Este correo electrónico ya existe en la base de datos!",
      email_wrong_error: "¡El correo electrónico proporcionado no es válido!",
    },
    fields: {
      address: "Calle y número",
      additionalInfo: "Información adicional",
      appealAuthority: "Nombre de la institución",
      appealDeadline: "Fecha",
      birthDate: "Fecha de nacimiento",
      city: "Ciudad",
      country: "País",
      diffrentIdentificationNumber: "Otro identificador",
      directory: "Carpeta",
      documentDate: "Fecha de emisión",
      drivingLicense: "Categoría de permiso de conducir",
      drivingLicenseIssuer: "Autoridad emisora del permiso",
      education: "Educación",
      identificationNumber: "DNI/CURP",
      identityDocumentType: "Documento de identidad",
      identityDocumentNumber: "Serie y número",
      interval: "Seleccionar intervalo de confianza",
      judgmentNumber: "Número de resolución",
      languageReport: "Seleccionar idioma del informe",
      legalBasis: "Base legal del examen",
      months: "meses",
      name: "Nombre",
      nextExamination: "Fecha del próximo examen",
      postalCode: "Código postal",
      recipient: "Seleccionar destinatario",
      sex: "Sexo",
      standard: "Seleccionar norma",
      surname: "Apellidos",
      years: "Años",
      year: "Año",
      examineeRegistryId: "Número de registro del sujeto",
      age: "Edad",
      date: "Fecha",
      institutionName: "Nombre de la institución",
      lang: "Idioma",
    },
    options: {
      activitiesStatus: {
        ok: "ok",
        user_error: "error de usuario",
        server_error: "error del servidor",
      },
      lang: {
        polish: "Polaco",
        english: "Inglés",
        espaniol: "Español",
        mexico: "Español Mexicano",
        russian: "Ruso",
        ukrain: "Ucraniano",
      },
      activities: {
        ALL: "todos",
        AUDIT_ERROR: "Error de registro de actividad",
        ALL_ITEMS: "Todas las actividades",
        APP_REGISTRATION: "Registro de aplicación",
        TEST_RESULT_SUBMITTED: "Resultados del examen enviados",
        CONSOLE_PIN_VERIFICATION: "Verificación de PIN en consola de usuario",
        SAVE_AGREEMENTS: "Guardado de consentimientos para examen y procesamiento de datos",
        TEST_SKIPPED: "Prueba omitida en consola",
        EXAMINATION_STARTED: "Examen iniciado",
        EXAMINATION_TERMINATED: "Examen terminado",
        ADMIN_LOGGED_OUT: "Administrador cerró sesión",
        ADMIN_CHANGE_LAB_ID: "Administrador inició sesión en cuenta de cliente",
        USER_LOGGED_IN: "Usuario inició sesión",
        USER_LOGGED_OUT: "Usuario cerró sesión",
        PASSWORD_CHANGE_REQUEST: "Solicitud de cambio de contraseña",
        PASSWORD_CHANGE: "Cambio de contraseña",
        PASSWORD_CHANGE_EXPIRED: "Cambio de contraseña después de expiración",
        PIN_CHANGE: "Cambio de PIN",
        DIRECTORY_CREATED: "Directorio creado",
        DIRECTORY_NAME_CHANGED: "Nombre de directorio cambiado",
        DIRECTORY_DELETED: "Directorio eliminado",
        EXAMINEE_ADDED: "Sujeto agregado",
        EXAMINEE_DATA_CHANGE: "Datos del sujeto modificados",
        EXAMINEE_DELETED: "Datos del sujeto eliminados",
        EXAMINEE_MOVED_TO_DIFFERENT_DIRECTORY: "Datos del sujeto movidos a otro directorio",
        EXAMINATION_ASSIGNED: "Examen asignado",
        EXAMINATION_DELETED: "Examen eliminado",
        EXAMINATION_REPORT_DOWNLOADED: "Documentos de informe descargados",
        CONTRACT_DOWNLOADED: "Contrato descargado",
        EXAMINATION_PROTOCOL_DOWNLOADED: "Protocolo de examen descargado",
        EXAMINATION_PROTOCOLS_ZIP_DOWNLOADED: "Protocolos de examen ZIP descargados",
        INTERVIEW_CHANGED: "Entrevista modificada",
        INTERVIEW_DOWNLOADED: "Entrevista descargada",
        ADMIN_LICENCE_ADDED: "Licencia de cuenta de cliente agregada",
        ADMIN_LICENCE_CHANGED: "Licencia de cuenta de cliente modificada",
        ADMIN_LICENCE_DELETED: "Licencia de cuenta de cliente eliminada",
        ADMIN_PSYCHOLOGICAL_LAB_ADDED: "Cuenta de cliente agregada",
        ADMIN_PSYCHOLOGICAL_LAB_DELETED: "Cuenta de cliente eliminada",
        ADMIN_PSYCHOLOGICAL_LAB_CHANGED: "Datos de cuenta de cliente modificados",
        ADMIN_USER_ADDED: "Usuario agregado a cuenta de cliente",
        ADMIN_USER_DELETED: "Usuario eliminado de cuenta de cliente",
        ADMIN_USER_CHANGED: "Datos de usuario de cuenta de cliente modificados",
        ADMIN_LOGGED_IN: "Administrador inició sesión",
      },
      norms: {
        norm_1: "OPT_1.0.PL",
        norm_2: "OPT_1.0.UA",
        norm_3: "OPT_1.0.RU",
      },
      bool: {
        true: "Sí",
        false: "No",
      },
      education: {
        primary: "Primaria",
        lowerSecondary: "Secundaria básica",
        secondary: "Secundaria",
        basicVocational: "Formación profesional básica",
        basicIndustry: "Formación técnica básica",
        secondaryIndustry: "Formación técnica media",
        higher: "Superior",
      },
      identityDocument: {
        idCard: "DNI",
        passport: "Pasaporte",
      },
      sex: {
        female: "Mujer",
        male: "Hombre",
      },
      country: {
        poland: "Polska",
        austria: "Austria",
        bulgaria: "Bułgaria",
        croatia: "Chorwacja",
        cyprus: "Cypr",
        czechRepublic: "Republika Czeska",
        denmark: "Dania",
        estonia: "Estonia",
        finland: "Finlandia",
        france: "Francja",
        greece: "Grecja",
        spain: "Hiszpania",
        netherlands: "Holandia",
        ireland: "Irlandia",
        lithuania: "Litwa",
        luxembourg: "Luksemburg",
        latvia: "Łotwa",
        malta: "Malta",
        germany: "Niemcy",
        portugal: "Portugalia",
        romania: "Rumunia",
        slovakia: "Słowacja",
        slovenia: "Słowenia",
        sweden: "Szwecja",
        hungary: "Węgry",
        italy: "Włochy",
        afghanistan: "Afganistan",
        albania: "Albania",
        angola: "Angola",
        saudiArabia: "Arabia Saudyjska",
        argentina: "Argentyna",
        armenia: "Armenia",
        azerbaijan: "Azerbejdżan",
        bangladesh: "Bangladesz",
        belarus: "Białoruś",
        bolivia: "Boliwia",
        bosniaAndHerzegovina: "Bośnia i Hercegowina",
        brazil: "Brazylia",
        chile: "Chile",
        china: "Chiny",
        chad: "Chad",
        montenegro: "Czarnogóra",
        democraticRepublicOfTheCongo: "Demokratyczna Republika Konga",
        dominicanRepublic: "Republika Dominikany",
        ecuador: "Ekwador",
        ethiopia: "Etiopia",
        gambia: "Gambia",
        ghana: "Ghana",
        georgia: "Gruzja",
        guatemala: "Gwatemala",
        honduras: "Honduras",
        india: "Indie",
        indonesia: "Indonezja",
        iran: "Iran",
        iceland: "Islandia",
        israel: "Izrael",
        jamaica: "Jamajka",
        japan: "Japonia",
        yemen: "Jemen",
        jordan: "Jordania",
        cambodia: "Kambodża",
        cameroon: "Kamerun",
        qatar: "Katar",
        kazakhstan: "Kazachstan",
        congo: "Kongo",
        southKorea: "Korea Południowa",
        laos: "Laos",
        northernMacedonia: "Północna Macedonia",
        madagascar: "Madagaskar",
        malaysia: "Malezja",
        mali: "Mali",
        mauritania: "Mauritania",
        mexico: "Meksyk",
        myanmar: "Myanmar",
        moldova: "Moldova",
        mozambique: "Mozambik",
        namibia: "Namibia",
        niger: "Niger",
        nigeria: "Nigeria",
        nicaragua: "Nikaragua",
        norway: "Norwegia",
        oman: "Oman",
        panama: "Panama",
        paraguay: "Paragwaj",
        peru: "Peru",
        centralAfricanRepublic: "Republika Środkowoafrykańska",
        russia: "Rosja",
        rwanda: "Rwanda",
        elSalvador: "Salwador",
        senegal: "Senegal",
        serbia: "Serbia",
        somalia: "Somali",
        unitedStatesOfAmerica: "Stany Zjednoczone Ameryki",
        sudan: "Sudan",
        southSudan: "Południowy Sudan",
        switzerland: "Szwajcaria",
        tajikistan: "Tadżykistan",
        thailand: "Tajlandia",
        turkey: "Turcja",
        uganda: "Uganda",
        ukraine: "Ukraina",
        uruguay: "Urugwaj",
        venezuela: "Wenezuela",
        unitedKingdom: "Zjednoczone Królestwo",
        vietnam: "Wietnam",
        zambia: "Zambia",
        unitedArabEmirates: "Zjednoczone Emiraty Arabskie",
        zimbabwe: "Zimbabwe",
      },
      recipient: {
        examinee: "Sujeto",
        psychologist: "Psicólogo",
        specialist: "Especialista",
      },
      languagesDocumentation: {
        pl: "polaco",
        en: "inglés",
      },
    },
    extraFields: "Datos complementarios",
    requiredFields: "Datos obligatorios",
    anonymous: "Examen anónimo",
    snackbar: {
      peselLength: "El número de identificación debe tener 11 dígitos",
      peselNumber: "El número de identificación solo puede contener números",
      required: "Debe completar todos los campos obligatorios.",
    },
  },
  FormValidation: {
    max: "El nombre no puede exceder los 128 caracteres",
    required: "Este campo es obligatorio",
    Pin: {
      length: "Debe contener 4 dígitos",
    },
    Password: {
      length: "Mínimo 8 caracteres",
      characters: "Debe contener una mayúscula, una minúscula, un carácter especial y un número",
    },
  },
  Helpdesk: {
    header: "Ayuda",
    subheader: "Mesa de ayuda",
    phoneNo: "tel.",
    mobile: "móvil",
    help: "Soporte remoto",
    contact: "Contacto",
    documents: {
      header: "Documentos de ayuda",
      labels: {
        download: "Descargar",
        name: "Nombre del documento",
      },
    },
  },
  Interview: {
    header: "Entrevista",
    inputAdditionalInformation: "Ingrese información adicional",
    inputHoursOfWork: "Ingrese las horas trabajadas en las últimas 24 horas",
    years: "Años",
    months: "Meses",
    hours: "Horas",
    tabs: {
      currentPsychophysicalState: "Situación de salud",
      socialAndFamilySituation: "Situación social y familiar",
      observation: "Observación",
      drivingVehicles: "Conducción de vehículos",
      occupationalMedicine: "Medicina laboral",
      securityAndDetectives: "Servicios de seguridad y detective",
      licensedActivities: "Actividades con licencia",
    },
    questions: {
      questionAnswers: {
        yes: "sí",
        no: "no",
      },
      questionTexts: {
        when: "¿Cuándo?",
        sinceWhen: "¿Desde cuándo?",
        forHowLong: "¿Hasta cuándo?",
        why: "¿Por qué motivo?",
        whatAreTheConsequences: "¿Cuáles fueron las consecuencias de salud?",
        describeTheCircumStancesOfUsage: "Describa las circunstancias de uso",
      },
      methodologies: {
        currentPsychophysicalState: {
          headers: {
            currentState: "Estado psicofísico actual",
            criticalEvents: "Eventos críticos",
          },
          questionExtendedTexts: {
            commonText: "Describa las circunstancias de este evento/situación",
          },
          questionTexts: {
            question1: "¿Ha consumido alcohol el sujeto en las últimas 48 horas?",
            question2: "¿Ha trabajado profesionalmente el sujeto durante la última o penúltima noche?",
            question3: "Número de horas trabajadas en las últimas 24 horas",
            question4: "Lesión en la cabeza que requirió atención médica",
            question5: "Pérdida de consciencia",
            question6: "Estancia hospitalaria",
            question7: "¿Ha habido o hay actualmente tratamiento por/ha habido alguna vez diagnóstico de las siguientes enfermedades:",
            question7a: "a. enfermedades del sistema digestivo (úlcera gástrica o duodenal, disfunción intestinal, etc.)",
            question7b: "b. enfermedades del corazón y sistema circulatorio (enfermedad coronaria, presión arterial alta)",
            question7c: "c. trastornos auditivos o visuales (excluyendo gafas correctivas)",
            question7d: "d. diabetes",
            question7e: "e. epilepsia",
            question7f: "f. enfermedades mentales (depresión, neurosis, tratamiento psiquiátrico (preguntar sobre tratamiento psiquiátrico de un familiar cercano)",
            question8: "Uso regular de medicamentos (preguntar si conoce los efectos secundarios y sobre otros medicamentos que afecten las funciones mentales)",
            question9: "Problemas de sueño",
            question10: "Uso de asistencia de:",
            question10a: "a. psicólogo",
            question10b: "b. psiquiatra",
            question10c: "c. neurólogo",
            question11: "Certificado de discapacidad",
            question12: "Problemas debido al consumo de alcohol",
            question13: "Estancia en centro de desintoxicación",
            question14: "Uso de sustancias intoxicantes/psicoactivas/drogas",
            question15: "Sugerencias de un médico o alguien cercano para buscar tratamiento debido a nerviosismo, distracción o problemas de memoria u orientación",
          },
        },
        socialAndFamilySituation: {
          questionTexts: {
            question1: "Conflicto o problema grave relacionado con el trabajo " +
              "(p.ej., accidente laboral, amonestación, tribunal laboral, despido disciplinario, acoso laboral)",
            question2: "Problemas durante la educación " +
              "(p.ej., repetición de curso, expulsión de la escuela, suspensión de derechos estudiantiles)",
            question3: "Conflicto familiar grave o problemas en situaciones privadas " +
              "(p.ej., casos judiciales, participación en peleas, se puede preguntar sobre estado civil, hijos)",
            question4: "Situación de peligro directo para la vida (p.ej., incendio, inundación y otros desastres)",
            question5: "Experiencia de violencia física contra uno mismo (p.ej., agresión, robo, golpes)",
            question6: "Participación en organizaciones sociales (p.ej., voluntariado)",
            question7: "Antecedentes penales (p.ej., recibir multas, sentencia judicial)",
          },
        },
        observation: {
          questionTexts: {
            question1: "Contacto con el entrevistador",
            question2: "Lenguaje y retórica",
            question3: "Estado de ánimo y actitud",
            question4: "Compostura y control del comportamiento y emociones",
          },
          questionAnswers: {
            question1a: "Establece muy buen contacto con el entrevistador; mantiene contacto visual; " +
              "se comporta con confianza y naturalidad, no se deja desestabilizar ni impacientar, " +
              "se comporta de manera espontánea y natural.",
            question1b: "Establece contacto con el entrevistador; mantiene contacto visual; se comporta con naturalidad, " +
              "aunque son visibles signos de cierto nerviosismo.",
            question1c: "Se comporta de manera distante, evita el contacto visual; se comporta de manera poco natural, " +
              "pequeños estímulos causan pérdida de equilibrio, son visibles claros signos de nerviosismo e impaciencia.",
            question2a: "Formula declaraciones usando un lenguaje correcto, " +
              "construye oraciones claras y legibles, mantiene una estructura lógica del discurso, " +
              "utiliza un vocabulario rico.",
            question2b: "Formula declaraciones correctamente, " +
              "generalmente mantiene su estructura lógica; vocabulario promedio pero correcto.",
            question2c: "Formula declaraciones cortas y superficiales, a menudo incoherentes o poco claras; " +
              "usa frases y formulaciones incorrectas; las oraciones son muy simples, " +
              "vocabulario muy pobre, ocurren errores gramaticales.",
            question3a: "Estado de ánimo observable muy alegre y optimista; satisfacción consigo mismo y su situación vital.",
            question3b: "Estado de ánimo observable moderado, actitud bastante positiva y perspectiva generalmente optimista del futuro.",
            question3c: "Reacciones emocionales observables desproporcionadamente fuertes, " +
              "clara tendencia hacia una percepción pesimista del mundo; muestra insatisfacción.",
            question4a: "Durante el examen controla adecuadamente el comportamiento y las emociones, " +
              "se adapta adecuadamente a las circunstancias emergentes.",
            question4b: "Durante el examen generalmente controla el comportamiento y las emociones; " +
              "la mayoría de las veces responde adecuadamente a las circunstancias emergentes.",
            question4c: "Durante el examen dificultades observables con el control del comportamiento y las emociones; " +
              "aparecen comportamientos o emociones inadecuados.",
          },
        },
        drivingVehicles: {
          headers: {
            drivingCharacteristics: "Características de las rutas",
          },
          questionTexts: {
            question1: "Experiencia general de conducción",
            question2: "Experiencia como conductor profesional",
            question3: "Kilometraje anual",
            question4: "Horas dedicadas a la conducción",
            question5: "Longitud de rutas",
            question6: "Variedad de rutas",
            question7: "Horario",
            question8: "Conductor de relevo",
            question9: "Área",
            question10: "Suspensión o revocación del permiso de conducir",
            question11: "Declaración de incapacidad para conducir",
            question12: "Sanción por infracción o delito de tráfico",
            question13: "Participación como conductor en accidente de tráfico",
            question14: "Participación como conductor en colisión de tráfico",
            question15: "Calificaciones y licencias adicionales",
          },
          questionAnswers: {
            question3a: "menos de 3.000 km anuales",
            question3b: "entre 3.000 – 15.000 km anuales",
            question3c: "entre 15.000 - 70.000 km anuales",
            question3d: "más de 70.000 km anuales",
            question3e: "difícil de decir",

            question4a: "menos de 5 horas semanales",
            question4b: "entre 5 - 15 horas semanales",
            question4c: "entre 15 - 40 horas semanales",
            question4d: "más de 40 horas semanales",
            question4e: "difícil de decir",

            question5a: "conduzco principalmente rutas cortas",
            question5b: "conduzco principalmente rutas largas",
            question5c: "conduzco tanto rutas largas como cortas",

            question6a: "suelo conducir rutas fijas",
            question6b: "suelo conducir rutas diferentes",

            question7a: "conduzco más frecuentemente de noche",
            question7b: "suelo conducir durante el día",
            question7c: "conduzco tanto de día como de noche",

            question8a: "conduzco principalmente solo sin relevo",
            question8b: "conduzco más frecuentemente con relevo",

            question9a: "conduzco principalmente en ciudad",
            question9b: "conduzco principalmente fuera de la ciudad",

            question13a: "como causante/co-causante",
            question13b: "como víctima",
            question13c: "no aplicable",

            question14a: "como causante/co-causante",
            question14b: "como víctima",
            question14c: "no aplicable",

            question15a: "transporte de materiales peligrosos",
            question15b: "transporte de pasajeros",
            question15c: "instructor de conducción",
            question15d: "examinador de conducción",
            question15e: "ninguno",
          },
        },
        occupationalMedicine: {
          headers: {
            workInformation: "Información laboral profesional",
            workCourseInformation: "Historial de carrera profesional",
            companyCarDrivers: "Conductores de vehículos de empresa",
            workingAtHeights: "Trabajo en altura",
            forkliftOperators: "Operadores de carretilla elevadora",
            minersAndMinerLifeguards: "Mineros y rescatistas mineros",
            trainDriver: "Conductores de tren",
          },
          questionTexts: {
            question1: "Profesión formada",
            question2: "Profesión actual",
            question3: "Lugar de trabajo / escuela",
            question4: "Cargo / funciones realizadas",
            question5: "Factores nocivos/molestos permanentes en el lugar de trabajo",
            question5f: "¿Cuáles?",
            question5g: "¿Cuáles?",
            question6: "Experiencia laboral total",
            question7: "Experiencia en el cargo actual",
            question8: "Enfermedad profesional diagnosticada",
            question8a: "¿Cuándo? ¿Por qué motivo?",
            question9: "Beneficios por discapacidad concedidos",
            question9a: "¿Por qué motivo? ¿Desde cuándo? ¿Hasta cuándo?",
            question10: "Sujeto involucrado en accidente laboral",
            question10a: "¿Cuándo? ¿Cuáles fueron las consecuencias para la salud?",
            question11: "Discapacidad determinada",
            question11a: "¿Por qué motivo? ¿Desde cuándo? ¿Hasta cuándo?",
            question12: "Experiencia general de conducción",
            question13: "Kilometraje anual",
            question14: "Horas dedicadas a la conducción",
            question15: "Declaración de incapacidad para conducir",
            question16: "Suspensión o revocación del permiso de conducir",
            question17: "Sanción por infracción o delito de tráfico",
            question18: "Participación como conductor en accidente de tráfico",
            question19: "Participación como conductor en colisión de tráfico",
            question20: "Cualificaciones para trabajo en altura",
            question21: "Realización de trabajos en altura (si es afirmativo, preguntar cuántos metros sobre el suelo)",
            question22: "Sensación subjetiva de miedo durante la permanencia en altura",
            question23: "Accidente durante el trabajo en altura",
            question23a: "¿Hubo daños a la propiedad o a la salud?",
            question24: "Cualificaciones para operar carretilla elevadora",
            question25: "Realización de trabajos relacionados con la operación y conducción de carretilla elevadora",
            question26: "Accidente durante la operación o conducción de carretilla elevadora",
            question26a: "¿Hubo daños a la propiedad o a la salud?",
          },
          questionAnswers: {
            question5a: "Trabajo monótono",
            question5b: "Constante alto flujo de información",
            question5c: "Alto nivel de ruido",
            question5d: "Trabajo con alta carga psicológica (responsabilidad y toma de decisiones)",
            question5e: "Radiación ionizante",
            question5f: "Factores químicos",
            question5g: "Otros",

            question13a: "menos de 3.000 km anuales",
            question13b: "entre 3.000 – 15.000 km anuales",
            question13c: "entre 15.000 - 70.000 km anuales",
            question13d: "más de 70.000 km anuales",
            question13e: "difícil de decir",

            question14a: "menos de 5 horas semanales",
            question14b: "entre 5 - 15 horas semanales",
            question14c: "entre 15 - 40 horas semanales",
            question14d: "más de 40 horas semanales",
            question14e: "difícil de decir",

            question18a: "como causante/co-causante",
            question18b: "como víctima",
            question18c: "no aplicable",

            question19a: "como causante/co-causante",
            question19b: "como víctima",
            question19c: "no aplicable",
          },
        },
        licensedActivity: {
          questionTexts: {
            question1: "Experiencia laboral total en establecimiento dedicado a actividades con licencia",
            question2: "Cargo/funciones realizadas",
            question3: "Experiencia laboral en el cargo actual en establecimiento dedicado a actividades con licencia",
            question4a: "Participación en accidente laboral",
            question4b: "¿Cuáles fueron las causas, consecuencias del accidente - hubo daños a la propiedad, salud?",
          },
        },
        detectivesSecurityGuardsAndWeaponPermits: {
          questionTexts: {
            question1: "Propósitos principales del uso de armas:",
            question1g: "¿Cuáles?",
            question2: "¿Ha habido alguna vez uso de armas u otros medios de coerción directa?",
            question3: "¿Con qué frecuencia se han usado armas en los últimos 5 años?",
          },
          questionAnswers: {
            question1a: "para fines profesionales (detective, guardia de seguridad)",
            question1b: "para fines de caza",
            question1c: "para fines deportivos",
            question1d: "para uso personal",
            question1e: "para fines museísticos, conmemorativos, científicos",
            question1f: "otros",
          },
        },
      },
    },
  },
  Language: { pl: "PL", ru: "RU", uk: "UK" },
  SearchEngine: {
    header: "Buscar examinados",
    placeholderMobile: "Buscar",
    placeholderDesktop: "Nombre, apellido, DNI/Identificador, fecha de asignación del examen (DD-MM-AAAA)",
    noResults: "No hay resultados de búsqueda",
    noResultsForFilter: "No se encontraron examinados que cumplan con los requisitos introducidos.",
    searchForExaminees: "Buscar examinados",
    enterFilter: "Puedes buscar por nombre, apellido, número de DNI/Identificador\n o por fecha de asignación del examen \n en formato DD-MM-AAAA.",
  },
  AdminSearchEngine: {
    header: "Buscar clientes",
    placeholderMobile: "Buscar",
    placeholderDesktop: "nombre, ID, dirección, número de identificación fiscal, fecha de creación, fecha de vencimiento de la cuenta del cliente en formato AAAA-MM-DD, nombre, apellido, correo electrónico del usuario, fecha de vencimiento de la licencia, metodología, prueba",
    noResults: "No hay resultados de búsqueda",
    noResultsForFilter: "No se encontraron clientes que cumplan con los requisitos introducidos.",
    searchForExaminees: "Buscar clientes",
    enterFilter: "Puede buscar por nombre, ID, dirección, número de identificación fiscal, fecha de creación, fecha de vencimiento de la cuenta del cliente en formato AAAA-MM-DD, nombre, apellido, correo electrónico del usuario, fecha de vencimiento de la licencia en formato AAAA-MM-DD, metodología, prueba.",
  },
  Snackbars: {
    createExamineeSuccess: "Examinado añadido correctamente",
    editExamineeSuccess: "Examinado editado correctamente",
    createPsychLabSuccess: "Cuenta de cliente añadida correctamente",
    editPsychLabSuccess: "Cuenta de cliente editada correctamente",
    failedRequest: "Se ha producido un problema: ",
    movingExamineesSuccess: "Trasladado a otra carpeta correctamente",
    passwordChangeSuccess: "Cambio de contraseña exitoso",
    pinChangeSuccess: "Cambio de PIN exitoso",
    success: "Acción exitosa",
    unrecognizedError: "Error no reconocido, compruebe la conexión a Internet",
    createExaminationSuccess: "Examen asignado correctamente",
    deleteExaminationSuccess: "Examen eliminado correctamente",
    editPsychUserSuccess: "Usuario editado correctamente",
    createPsychUserSuccess: "Usuario añadido correctamente",
    createPsychLicSuccess: "Licencia añadida correctamente",
    editPsychLicSuccess: "Licencia editada correctamente",
  },
  UserSettings: {
    header: "Configuración de la cuenta",
    headerChangePassword: "Cambiar contraseña",
    headerChangePin: "Cambiar PIN",
    setNewPin: "Establecer nuevo PIN",
    pinInfo: "Introduce el PIN que utilizarás para iniciar sesión en la consola del psicólogo.",
    headerMobile: "Configuración",
    facilityInfo: "Información del centro",
    userInfo: "Información del usuario",
    passwordChange: "Cambio de contraseña",
    passwordExpiry: "Tu contraseña caduca: ",
    pinChange: "Cambio de PIN",
    infoSubHeaders: {
      labName: "Nombre",
      address: "Dirección",
      cin: "Número de registro",
      tin: "NIF",
      firstName: "Nombre",
      lastName: "Apellidos",
      email: "Correo electrónico",
      dateExpire: "Fecha de caducidad de la cuenta",
      licenseNumber: "Número de licencia"
    },
    helpdeskMsgUser: "Para cambiar la información del centro o usuario, llama al:",
    helpdeskMsgLab: "Para cambiar la información del centro o usuario, llama al:",
  },
  Backend: {
    User_activation_code_has_already_been_used: "El código de activación ya ha sido utilizado.",
    User_activation_code_does_not_exist: "El código de activación proporcionado no existe.",
    User_with_given_id_does_not_exist: "El usuario con el ID proporcionado no existe.",
    Token_not_recognized: "Token no reconocido.",
    Unrecognized_token_Please_regenerate_password_reset_link: "Token no reconocido, por favor genera un nuevo enlace de restablecimiento de contraseña.",
    Invalid_token_Please_regenerate_password_reset_link: "Token inválido, por favor genera un nuevo enlace de restablecimiento de contraseña.",
    Validation_failed: "La validación de los datos ha fallado.",
    Could_not_bind_users_PsychologicalLab_with_the_examinee: "No se pudo vincular el centro del usuario con el examinado.",
    Could_not_bind_users_PsychologicalLab_with_the_directory: "No se pudo vincular el centro del usuario con el directorio.",
    The_password_cannot_be_the_same_as_the_previous_one: "La contraseña no puede ser igual a la anterior.",
    Incorrect_credentials: "Contraseña incorrecta.",
    Unrecognized_email: "Dirección de correo electrónico no reconocida o cuenta no activada.",
    Directory_has_related_Examinees: "No se puede eliminar el Directorio: El directorio tiene examinados relacionados.",
    Unable_to_delete_default_directory: "No se puede eliminar el Directorio Predeterminado.",
    The_new_pin_cannot_be_the_same_as_the_previous_one: "El nuevo PIN no puede ser igual al anterior.",
    Directory_with_given_name_already_exists: "Ya existe un directorio con ese nombre.",
    Examination_Card_does_not_have_a_defined_NormSet: "La Tarjeta de Examen no tiene una norma de examen asignada.",
    Examinee_with_the_given_id_exists_in_this_psychological_lab: "Ya existe un examinado con el DNI proporcionado en la base de datos.",
    The_Examinee_has_related_Examinations: "No se puede eliminar el Examinado: El examinado tiene exámenes relacionados.",
    Unrecognized_proxy_error_occurred_while_fetching_legacy_data: "Error no reconocido al recuperar datos heredados.",
    Legacy_data_instance_not_found: "No se encontraron datos heredados.",
    Legacy_data_validation_failed: "Error en la validación de datos heredados.",
    Unable_to_parse_Examination_Aim: "Error en la validación del Objetivo del Examen.",
    Unable_to_parse_Occupational_Medicine_Basic_Info_data: "Error en la validación de datos de Medicina Laboral.",
    Ongoing_examination: "No se puede eliminar el Examen. Examen en curso.",
  },
  Onboarding: {
    errorText: "Se produjo un error al establecer la contraseña, por favor inténtelo de nuevo más tarde.",
    successTitle: "Contraseña y PIN establecidos",
    success: "Su contraseña y PIN han sido guardados. Ahora puede iniciar sesión en la aplicación por primera vez utilizando su dirección de correo electrónico y contraseña.",
    setNewPasswordInfo: "Introduzca la contraseña que utilizará para iniciar sesión en el sistema.",
    setPasswordFormLabel: "Establecer contraseña",
    setPinInfo: "Introduzca el PIN que utilizará para iniciar sesión en la consola del psicólogo.",
    setPinFormLabel: "Establezca su PIN",
  },
  ResetPassword: {
    formHeader: "Restablecer Contraseña",
    resetInfo: "Introduzca la dirección de correo electrónico de su cuenta para recibir un enlace de restablecimiento de contraseña.",
    resetEmailSentPt1: "Se ha enviado un correo electrónico a: ",
    resetEmailSentPt2: " con las instrucciones para el proceso de restablecimiento de contraseña.",
    errorText: "Se produjo un error al restablecer la contraseña, por favor inténtelo de nuevo más tarde.",
    successTitle: "Contraseña Guardada",
    success: "Su contraseña ha sido cambiada. Ahora puede iniciar sesión en la aplicación utilizando su dirección de correo electrónico y nueva contraseña.",
    setNewPassword: "Establecer nueva contraseña",
    setNewPasswordInfo: "Introduzca la contraseña que utilizará para iniciar sesión en el sistema.",
  },
  FormLabels: {
    email: "Correo electrónico",
    password: "Contraseña",
    currentPassword: "Contraseña actual",
    newPassword: "Nueva contraseña",
    pin: "PIN",
  },
  FormValidationErrors: {
    required: "Este campo es obligatorio",
    email: "El valor introducido no es una dirección de correo electrónico",
  },
  Login: {
    header: "INICIAR SESIÓN",
    adminHeader: "PANEL DE ADMINISTRACIÓN",
    error: "Correo electrónico o contraseña incorrectos.",
    warning: "El BLOQ MAYÚS está activado",
    reset: "Restablecer contraseña",
    Instance_configured_incorrectly: "Error de configuración del sistema. Comunique el problema al soporte técnico.",
    No_recaptcha_token_provided: "Error de recaptcha. Comunique el problema al soporte técnico.",
    Validation_failed: "Correo electrónico o contraseña incorrectos.",
    Laboratory_is_inactive_or_deleted: "Cuenta de cliente inactiva",
    User_account_is_not_active: "La cuenta de usuario ha sido eliminada",
    User_account_has_been_deleted: "User account is inactive",
    Captcha_error: "Error al completar el captcha. Por favor, inténtelo de nuevo.",
    Access_denied: "Acceso denegado"
  },
  Directory: {
    defaultFolderName: "Carpeta predeterminada",
    header: "Registro de examinados",
    popup: {
      create: "Introduzca el nombre de la carpeta",
      edit: "Cambiar nombre de la carpeta",
    },
    emptyState: {
      header: "No hay examinados en la carpeta seleccionada",
      info: "Para ver la lista de examinados en la carpeta, añada al menos un examinado",
    },
    labels: {
      name: "Nombre de la carpeta",
      owner: "Propietario",
      examineeCount: "Personas",
    },
    kebabMenu: {
      addExaminee: "Añadir examinado",
      addDirectory: "Añadir carpeta",
      edit: "Editar nombre de la carpeta",
      delete: "Eliminar carpeta",
    },
    popups: {
      delete: {
        confirmationHeader: "¿Está seguro de que desea eliminar la carpeta?",
        confirmationText: "Una vez eliminada, la carpeta no podrá ser restaurada.",
      },
    },
  },
  DirectoryPreview: {
    headerPopup: "Mover examinados",
    labels: {
      firstName: "Nombre",
      lastName: "Apellidos",
      peselSlashIdentifier: "DNI / Identificador",
      lastModification: "Última actividad",
    },
    kebabMenu: {
      edit: "Editar datos del examinado",
      move: "Mover examinado",
      delete: "Eliminar examinado",
    },
    breadcrumbs: "Registro de examinados",
  },
  Pagination: {
    outOf: "de",
  },
  PasswordExpired: {
    popup: {
      header: "La contraseña ha caducado",
      text: "Será redirigido al proceso de restablecimiento de contraseña para establecer una nueva.",
    },
    form: {
      header: "Establecer nueva contraseña",
      text: "Introduzca la contraseña que utilizará para iniciar sesión en el sistema.",
    },
    success: {
      header: "Contraseña guardada",
      text: "Su contraseña ha sido cambiada. Ahora puede acceder a la aplicación.",
    },
  },
  DocumentationManagement: {
    snackbar: {
      save: "Documento guardado correctamente",
    },
    names: {
      ExaminationCard: "Tarjeta de examen",
      ExaminationCardTEST2WORK: "Tarjeta de examen TEST2WORK",
      ExaminationCardTEST2SAFE: "Tarjeta de examen TEST2SAFE",
      ExaminationCardTEST2ARMS: "Tarjeta de examen TEST2ARMS",
      ExaminationCardTEST2DRIVE: "Tarjeta de examen TEST2DRIVE",
      PsychologicalConsultation: "Consulta psicológica",
      PsychologicalConsultationTEST2WORK: "Consulta psicológica",
      PsychologicalConsultationTEST2SAFE: "Consulta psicológica",
      PsychologicalConsultationTEST2ARMS: "Consulta psicológica",
      PsychologicalConsultationTEST2DRIVE: "Consulta psicológica",
      PsychologicalOpinion: "Informe psicológico",
      PsychologicalOpinionTEST2WORK: "Informe psicológico",
      PsychologicalOpinionTEST2SAFE: "Informe psicológico - Detectives",
      PsychologicalOpinionTEST2ARMS: "Informe psicológico",
      PsychologicalOpinionTEST2DRIVE: "Informe psicológico",
      DriverJudgment: "Dictamen de conducción de vehículos",
      DriverJudgmentTEST2WORK: "Dictamen de conducción de vehículos",
      DriverJudgmentTEST2SAFE: "Dictamen de conducción de vehículos",
      DriverJudgmentTEST2ARMS: "Dictamen de conducción de vehículos",
      DriverJudgmentTEST2DRIVE: "Dictamen de conducción de vehículos",
      DrivingExaminerJudgment: "Dictamen de examinador de conducción",
      DrivingExaminerJudgmentTEST2WORK: "Dictamen de examinador de conducción",
      DrivingExaminerJudgmentTEST2SAFE: "Dictamen de examinador de conducción",
      DrivingExaminerJudgmentTEST2ARMS: "Dictamen de examinador de conducción",
      DrivingExaminerJudgmentTEST2DRIVE: "Dictamen de examinador de conducción",
      DrivingInstructorJudgment: "Dictamen de profesor de autoescuela",
      DrivingInstructorJudgmentTEST2WORK: "Dictamen de profesor de autoescuela",
      DrivingInstructorJudgmentTEST2SAFE: "Dictamen de profesor de autoescuela",
      DrivingInstructorJudgmentTEST2ARMS: "Dictamen de profesor de autoescuela",
      DrivingInstructorJudgmentTEST2DRIVE: "Dictamen de profesor de autoescuela",
      DrivingTechniqueInstructorJudgment: "Dictamen de instructor de técnica de conducción",
      EmergencyVehicleDriverJudgment: "Dictamen de vehículos de emergencia",
      EmergencyVehicleDriverJudgmentTEST2WORK: "Dictamen de vehículos de emergencia",
      EmergencyVehicleDriverJudgmentTEST2SAFE: "Dictamen de vehículos de emergencia",
      EmergencyVehicleDriverJudgmentTEST2ARMS: "Dictamen de vehículos de emergencia",
      EmergencyVehicleDriverJudgmentTEST2DRIVE: "Dictamen de vehículos de emergencia",
      FirearmLicenseJudgment: "Dictamen de licencia de armas",
      FirearmLicenseJudgmentTEST2WORK: "Dictamen de licencia de armas",
      FirearmLicenseJudgmentTEST2SAFE: "Dictamen de licencia de armas",
      FirearmLicenseJudgmentTEST2ARMS: "Dictamen de licencia de armas",
      FirearmLicenseJudgmentTEST2DRIVE: "Dictamen de licencia de armas",
      MilitaryExplosivesJudgment: "Dictamen de armas y material policial o militar",
      MilitaryExplosivesJudgmentTEST2WORK: "Dictamen de armas y material policial o militar",
      MilitaryExplosivesJudgmentTEST2SAFE: "Dictamen de armas y material policial o militar",
      MilitaryExplosivesJudgmentTEST2ARMS: "Dictamen de armas y material policial o militar",
      MilitaryExplosivesJudgmentTEST2DRIVE: "Dictamen de armas y material policial o militar",
      OccupationalMedicineJudgmentTEST2WORK: "Dictamen de medicina laboral",
      OccupationalMedicineJudgmentTEST2SAFE: "Dictamen de medicina laboral",
      OccupationalMedicineJudgmentTEST2ARMS: "Dictamen de medicina laboral",
      OccupationalMedicineJudgmentTEST2DRIVE: "Dictamen de medicina laboral",
      PhysicalProtectionJudgment: "Dictamen de vigilante de seguridad",
      PhysicalProtectionJudgmentTEST2WORK: "Dictamen de vigilante de seguridad",
      PhysicalProtectionJudgmentTEST2SAFE: "Dictamen de vigilante de seguridad física",
      PhysicalProtectionJudgmentTEST2ARMS: "Dictamen de vigilante de seguridad",
      PhysicalProtectionJudgmentTEST2DRIVE: "Dictamen de vigilante de seguridad",
      ProfessionalDriverJudgment: "Dictamen de conductor profesional",
      ProfessionalDriverJudgmentTEST2WORK: "Dictamen de conductor profesional",
      ProfessionalDriverJudgmentTEST2SAFE: "Dictamen de conductor profesional",
      ProfessionalDriverJudgmentTEST2ARMS: "Dictamen de conductor profesional",
      ProfessionalDriverJudgmentTEST2DRIVE: "Dictamen de conductor profesional"
    },
    emptyState: {
      header: "El examen no se ha completado",
    },
    report: {
      details: {
        assignDate: "Fecha de asignación del examen",
        endDate: "Fecha de finalización del examen",
        firstName: "Nombre",
        PESEL: "DNI",
        identificationNumber: "Identificador",
        idExamination: "ID del examen",
        lastName: "Apellidos",
        name: "Nombre del examen",
        status: "Estado del examen",
      },
      statusExamination: {
        skip: "Algunas pruebas fueron omitidas o interrumpidas",
      },
      header: {
        language: "Seleccione el idioma del informe",
        standard: "Seleccione el estándar",
        recipient: "Seleccione el destinatario",
        interval: "Seleccione el intervalo de confianza",
        reportInterpretation: "Informe interpretativo",
        reportDiagnostic: "Informe diagnóstico",
      },
    },
    consultation: {
      breadcrumbs: "Consulta psicológica",
      examineeData: "Datos del examinado",
      statement: "declaro",
    },
    opinion: {
      breadcrumbs: "Informe psicológico",
      breadcrumbsAppeal: "Informe psicológico de apelación",
      examineeData: "Datos del examinado",
      statement: "EN BASE AL EXAMEN PSICOLÓGICO REALIZADO, DECLARO",
    },
    judgment: {
      isApealTitle: "DICTAMEN DE APELACIÓN",
      appeal: "Este dictamen puede ser apelado ante",
      appealDate: "En el plazo de",
      appealDay: "En el plazo de",
      appealDay2: "días desde su recepción",
      appealDayTlt: "Número de días",
      drivingLicense: "En relación al permiso de conducir clase",
      judgmentNumber: "Dictamen psicológico nº",
      judgmentNumberAppeal: "Dictamen psicológico nº",
      legalBais: "Base legal del examen",
      statement: "Declaro*",
      occupationalMedicineStatement: "Dictamino*",
      statementThat: "Declaro que",
      nextLegalBasis: "Base legal para el próximo examen:",
      remarks: "Observaciones",
      ART_1: "art. 39k apartado 3 punto 1",
      ART_2: "art. 39k apartado 3 punto 2",
      examiner_ART_1: "art. 34 apartado 5 punto 1",
      examiner_ART_2: "art. 34 apartado 5 punto 2",
      header: {
        base: "Dictamen",
        baseAppeal: "Dictamen de apelación",
        baseAppealFooter: "Este dictamen es definitivo.",
        civilExplosives: "Actividad autorizada - para uso civil",
        civilExplosivesAppeal: "Actividad autorizada - para uso civil - apelación",
        driver: "Conducción de vehículos",
        emergencyVehicleDriver: "Conductor de vehículo de emergencia",
        firearmLicense: "Licencia de armas",
        militaryExplosives: "Actividad autorizada - de uso militar o policial",
        physicalProtection: "Vigilante de seguridad",
        profesionalDriver: "Puesto de conductor",
        drivingInstructor: "Profesor",
        drivingExaminer: "Examinador",
        occupationalMedicine: "Medicina laboral"
      },
      emergencyVehicleDriver: {
        true: "no existen contraindicaciones para conducir vehículos de emergencia o de transporte de valores",
        false: "existen contraindicaciones para conducir vehículos de emergencia o de transporte de valores"
      },
      drivingVehicles: {
        true: "no presenta contraindicaciones psicológicas para la conducción de vehículos",
        false: "presenta contraindicaciones psicológicas para la conducción de vehículos"
      },
      physicalProtection: {
        true: "es apto para desempeñar las funciones de vigilante de seguridad",
        false: "no es apto para desempeñar las funciones de vigilante de seguridad"
      },
      civilExplosives: {
        true: "no presenta alteraciones en el funcionamiento psicológico y por lo tanto puede realizar actividades relacionadas con la adquisición y almacenamiento de explosivos destinados a uso civil",
        false: "presenta alteraciones en el funcionamiento psicológico y por lo tanto no puede realizar actividades relacionadas con la adquisición y almacenamiento de explosivos destinados a uso civil"
      },
      drivingInstructor: {
        true: "no presenta contraindicaciones para desempeñar funciones de profesor/examinador/instructor de técnica de conducción",
        false: "presenta contraindicaciones para desempeñar funciones de profesor/examinador/instructor de técnica de conducción"
      },
      drivingExaminer: {
        true: "no presenta contraindicaciones para desempeñar funciones de profesor/examinador/instructor de técnica de conducción",
        false: "presenta contraindicaciones para desempeñar funciones de profesor/examinador/instructor de técnica de conducción"
      },
      militaryExplosives: {
        true: "no presenta alteraciones significativas en el funcionamiento psicológico que impidan realizar o dirigir actividades comerciales en el ámbito de fabricación y comercio de explosivos, armas, municiones y productos y tecnología de uso militar o policial",
        false: "presenta alteraciones significativas en el funcionamiento psicológico que impiden realizar o dirigir actividades comerciales en el ámbito de fabricación y comercio de explosivos, armas, municiones y productos y tecnología de uso militar o policial"
      },
      professionalDriver: {
        true: "no presenta contraindicaciones psicológicas para desempeñar el puesto de conductor",
        false: "presenta contraindicaciones psicológicas para desempeñar el puesto de conductor"
      },
      firearmLicense: {
        true: "no pertenece a las personas mencionadas en el Art. 15 párrafo 1 punto 3 de la Ley del 21 de mayo de 1999 sobre armas y municiones y puede poseer armas",
        false: "pertenece a las personas mencionadas en el Art. 15 párrafo 1 punto 3 de la Ley del 21 de mayo de 1999 sobre armas y municiones y no puede poseer armas"
      },
      occupationalMedicine: {
        true: "no presenta contraindicaciones psicológicas para",
        false: "presenta contraindicaciones psicológicas para",
        justification: "Justificación"
      },
    },
    ExaminationCard: {
      header: "Tarjeta de examen",
      headerTEST2WORK: "Tarjeta de examen TEST2WORK",
      headerTEST2SAFE: "Tarjeta de examen TEST2SAFE",
      headerTEST2ARMS: "Tarjeta de examen TEST2ARMS",
      headerTEST2DRIVE: "Tarjeta de examen TEST2DRIVE",
      fetchUpdated: "Actualizar con datos de la entrevista y resultados",
      tooltipFetchUpdated: "Esta acción eliminará todos los cambios no guardados del Curso del Examen",
      tooltipFetchUpdatedDisabled: "Para desbloquear esta acción, seleccione el estándar de examen.",
      tabs: {
        examinee: "Persona examinada",
        courseOfExamination: "Curso del examen",
        results: "Resultados del examen psicológico"
      },
      examinee: {
        chosenNormSet: "Estándar aplicado en el examen",
        examinationDate: "Fecha del examen",
        previousExaminationDate: "Fecha del examen anterior",
        aim: "Objetivo del examen",
        entityDirectingToExamination: "Entidad que deriva al examen",
        t2dAim: {
          drivingVehiclesAim: "Determinación de ausencia o presencia de contraindicaciones para la conducción de vehículos.",
          professionalDriverAim: "Determinación de ausencia o presencia de contraindicaciones para desempeñar el puesto de conductor.",
          emergencyDriverAim: "Determinación de ausencia o presencia de contraindicaciones para conducir vehículos de emergencia o de transporte de valores.",
          drivingInstructorAim: "Determinación de ausencia o presencia de contraindicaciones para desempeñar funciones de profesor/examinador/instructor de técnica de conducción."
        }
      },
      examinationCourse: {
        forcedUpdateHeader: "Actualizar Tarjeta de Examen con Datos de la Entrevista y Resultados",
        forcedUpdateBody: "Para desbloquear el formulario, complete la Entrevista del Examen, seleccione un estándar en la pestaña Persona Examinada y actualice la Tarjeta de Examen con los Datos de la Entrevista y Resultados.",
        header: "Datos de la entrevista directa y observación de la persona examinada",
        methodology: "Métodos de examen y resultados",
        recommendations: "Recomendaciones",
        conclusions: "Conclusiones",
        conclusionsRecomendation: "Conclusiones y recomendaciones",
        careerHistory: "Historial laboral",
        examinationResultsHeader: "Nombre de la prueba y resultados (dentro de la metodología recomendada)",
        examinationExtendedResultsHeader: "Nombre de la prueba y resultados adicionales",
        cognitiveResultsHeader: "Evaluación y descripción de la capacidad intelectual y procesos cognitivos del examinado (dentro de la metodología recomendada)",
        cognitiveExtendedResultsHeader: "Evaluación y descripción de la capacidad intelectual y procesos cognitivos - resultados adicionales",
        personalityResultsHeader: "Evaluación y descripción de la personalidad del examinado, incluyendo funcionamiento en situaciones difíciles y madurez social (dentro de la metodología recomendada)",
        personalityExtendedResultsHeader: "Evaluación y descripción de la personalidad del examinado, incluyendo funcionamiento en situaciones difíciles y madurez social - resultados adicionales",
        psychomotorResultsHeader: "Rendimiento psicomotor (dentro de la metodología recomendada)",
        psychomotorExtendedResultsHeader: "Rendimiento psicomotor - resultados adicionales",
        occupationalMedicineAdditionalInfo: "Información adicional - Medicina laboral",
        examinationResultsT2WHeader: "Metodología recomendada",
        examinationExtendedResultsT2WHeader: "Adicional",
        addExaminationExtendedResults: "Añadir resultado adicional",
        deletePopup: {
          headerText: "¿Está seguro de que desea eliminar este resultado?",
          contentText: "Después de eliminar el resultado, puede restaurar su contenido original actualizando los datos",
          confirmationText: "Sí, quiero eliminar el resultado"
        }
      },
      judgment: {
        result: "Resultado del examen psicológico",
        sent: "Dictamen/copia del dictamen enviado, si procede",
        judgment: "Contenido del dictamen",
        justification: "Justificación del dictamen",
        justificationText: "Contenido de la justificación",
        recommendations: "Recomendaciones",
        to: "para",
        t2d: {
          lack: "no existen",
          existing: "existen",
          questions: {
            drivingVehiclesContraindication: "Contraindicaciones para la conducción de vehículos.",
            professionalDriverContraindication: "Contraindicaciones para desempeñar el puesto de conductor.",
            emergencyVehicleDriverContraindication: "Contraindicaciones para conducir vehículos de emergencia o de transporte de valores.",
            drivingInstructorContraindication: "Contraindicaciones para desempeñar funciones de profesor/examinador/instructor de técnica de conducción."
          }
        }
      },
    },
  },
};
