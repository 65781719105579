/* eslint-disable camelcase */
export const pl = {
  HelpDocs: {
    contractTitle: "Kontrakty",
    contractName1: "Kontrakt Psychologiczny 2024",
    contractName2: "Kontrakt psychologiczny RU 2024",
    contractName3: "Kontrakt Psychologiczny UA 2024",
    contractName4: "Polityka ochrony danych osobowych - klauzula Informacyjna 2024",
    contractFileName1: "/help-documents/pl-pl/kontrakt_psychologiczny_2024.pdf",
    contractFileName2: "/help-documents/pl-pl/kontrakt_psychologiczny_ru_2024.pdf",
    contractFileName3: "/help-documents/pl-pl/kontrakt_psychologiczny_ua_2024.pdf",
    contractFileName4: "/help-documents/pl-pl/polityka_ochrony_danych_osobowych_klauzula_informacyjna_2024.pdf",
    handbookTitle: "Podręcznik i katalog testów",
    handbookName1: "Charakterystyka obszarów diagnozy",
    handbookName2: "Testy psychomotoryczne",
    handbookName3: "Testy poznawcze",
    handbookName4: "Testy osobowości",
    handbookName5: "Katalog testów 03-2024",
    handbookFileName1: "/help-documents/pl-pl/charakterystyka_obszarow_diagnozy.pdf",
    handbookFileName2: "/help-documents/pl-pl/testy_psychomotoryczne.pdf",
    handbookFileName3: "/help-documents/pl-pl/testy_poznawcze.pdf",
    handbookFileName4: "/help-documents/pl-pl/testy_osobowosci.pdf",
    handbookFileName5: "/help-documents/pl-pl/katalog_testow_03_2024.pdf",
    modulesTitle: "Moduły wywiadów",
    modulesName1: "Sytuacja zdrowotna",
    modulesName2: "Sytuacja społeczna i rodzinna",
    modulesName3: "Obserwacja",
    modulesName4: "Detektywi ochrona broń MTD I",
    modulesName5: "Koncesja MTD II",
    modulesName6: "Medycyna pracy MTD III",
    modulesName7: "Kierowcy służbowi MTD III",
    modulesName8: "Praca wysokość MTD III",
    modulesName9: "Wózki widłowe MTD III",
    modulesName10: "Psychologia transportu kierowanie pojazdami MTD IV",
    modulesFileName1: "/help-documents/pl-pl/sytuacja_zdrowotna.pdf",
    modulesFileName2: "/help-documents/pl-pl/sytuacja_spoleczna_i_rodzinna.pdf",
    modulesFileName3: "/help-documents/pl-pl/obserwacja.pdf",
    modulesFileName4: "/help-documents/pl-pl/detektywi_ochrona_bron_mtd_I.pdf",
    modulesFileName5: "/help-documents/pl-pl/koncesja_mtd_II.pdf",
    modulesFileName6: "/help-documents/pl-pl/medycyna_pracy_mtd_III.pdf",
    modulesFileName7: "/help-documents/pl-pl/kierowcy_sluzbowi_mtd_III.pdf",
    modulesFileName8: "/help-documents/pl-pl/praca_wysokosc_mtd_III.pdf",
    modulesFileName9: "/help-documents/pl-pl/wozki_widlowe_mtd_III.pdf",
    modulesFileName10: "/help-documents/pl-pl/psychologia_transportu_kierowanie pojazdami_mtd_IV.pdf",
  },
  Format: {
    ViewDateTime: "DD.MM.YYYY HH:mm",
    FormDateFormat: "DD.MM.YYYY",
    ExamineeViewDateFormat: "DD.MM.YYYY",
  },
  Buttons: {
    accept: "Zgadzam się",
    addExaminee: "Dodaj badanego",
    addPsychLab: "Dodaj konto klienta",
    addPsychUser: "Dodaj konto użytkownika",
    addUser: "Dodaj konto użytkownika",
    addDirectory: "Dodaj folder",
    assign: "Przydziel",
    alreadyAssigned: "Przydzielono",
    assignExamination: "Przydziel badanie",
    alreadyAssignedExamination: "Badanie w toku",
    back: "Powrót",
    backToLogin: "Wróć na stronę logowania",
    begin: "Rozpocznij",
    cancel: "Anuluj",
    change: "Zmień",
    clear: "Wyczyść",
    close: "Zamknij",
    confirm: "Zatwierdź",
    continue: "Dalej",
    createExamination: "Przypisz badanie",
    delete: "Usuń",
    deleteExaminee: "Usuń badanego",
    deletePsychLab: "Usuń konto klienta",
    deletePsychLabLic: "Usuń licencję",
    downloadAll: "Pobierz wszystkie",
    edit: "Edytuj",
    editExaminee: "Edytuj badanego",
    editPsychLab: "Edytuj konto klienta",
    editPsychLabLic: "Edytuj licencję",
    addPsychLabLic: "Dodaj licencję",
    editPsychLabUser: "Edytuj konto użytkownika",
    examinationWithNoLawBase: "Badanie bez podstawy prawnej",
    examinationWithLawBase: "Badanie z podstawą prawną",
    exit: "Wyjdź",
    generate: "Wygeneruj",
    generateAndPrint: "Wygeneruj i wydrukuj",
    go: "Przejdź",
    goToDashboard: "Przejdź do panelu użytkownika",
    login: "Zaloguj",
    loginPsychLab: "Zaloguj do konta klienta",
    move: "Przenieś",
    moveExaminee: "Przenieś badanego",
    moveExaminees: "Przenieś badanych",
    ok: "Ok",
    print: "Wydrukuj",
    printContract: "Wydrukuj kontrakt",
    save: "Zapisz",
    savePassword: "Zapisz hasło",
    savePin: "Zapisz PIN",
    saveAndPrint: "Zapisz i wydrukuj",
    saveSettings: "Zapisz ustawienia",
    setPin: "Ustaw PIN",
    search: "Szukaj",
    setPassword: "Ustaw hasło",
    setNewPassword: "Ustaw nowe hasło",
    sendLink: "Wyślij Link",
    skipTests: "Pomiń testy",
    understood: "Rozumiem",
    showActivities: "Wyświetl",
    exportActivities: "Eksport do pliku XLS",
  },
  Common: {
    menu: {
      contact: "Pomoc",
      dashboard: "Panel użytkownika",
      folders: "Kartoteka badanych",
      search: "Wyszukaj",
      logout: "Wyloguj",
      settings: "Ustawienia",
      psychlabs: "Kartoteka klientów",
      breadcrumbsPsychLab: "Kartoteka klientów",
    },
    seo: {
      addExaminee: "Dodaj badanego",
      addPsychLabLic: "Dodaj licencję",
      addPsychLab: "Dodaj konto klienta",
      dashboard: "Panel użytkownika",
      editExaminee: "Edytuj badanego",
      helpdesk: "Pomoc",
      helpdeskDocuments: "Dokumenty pomocy",
      judgement: "Orzeczenie",
      login: "Logowanie",
      onboarding: "Onboarding",
      report: "Raport",
      resetPassword: "Resetowanie hasła",
      setNewPassword: "Ustawianie nowego hasła",
      search: "Wyszukiwarka",
      folders: "Kartoteka badanych",
      settings: "Ustawienia",
      examinationAssignment: "Przydzielanie badania z podstawą prawną",
      examinationCard: "Karta Badania",
    },
    warningPopup: {
      text: "Wprowadzone przez Ciebie zmiany nie zostaną zapisane",
      header: "Czy na pewno chcesz wyjść?",
      textSave: "Wymagane pola mogą znajdować się również na pozostałych zakładkach",
      headerSave: "Proszę uzupełnić wszystkie wymagane pola!",
      promptLogout: "Zostaniesz automatycznie wylogowany za PROMPT_TIME_MINUTES minutę z powodu braku aktywności."
    },
    logAdminAsUserPopup: {
      text: "Nie udało się zalogować na konto klienta",
      header: "Błąd",
    },
    deleteExamineePopup: {
      text: "Po usunięciu badanego nie będzie możliwości przywrócenia danych",
      header: "Czy na pewno chesz usunąć badanego?",
    },
  },
  Contact: {
    header: "Pomoc techniczna",
  },
  Cookies: {
    header: "Pozwolenie na użycie cookies",
    consent: `Ta strona wykorzystuje pliki cookies, które są używane, by zapewnić jak najlepsze działanie tej aplikacji. Jeżeli wyrażasz zgodę na zapisywanie informacji zawartej w cookies kliknij przycisk ZGADZAM SIĘ. 
    Jeśli nie wyrazisz zgody, nie będzie możliwości korzystania z aplikacji i wykonania przydzielonych procedur.`,
  },
  Dashboard: {
    header: "Ostatnio dodane osoby",
    footer: {
      lastLogin: "Ostatnie logowanie",
      lastFailedLogin: "Ostatnie nieudane logowanie",
      passwordExpired: "Twoje hasło straci ważność za",
      day: "dzień",
      days: "dni",
    },
    emptyState: {
      header: "Brak ostatnio dodanych badanych",
      info: "Aby zobaczyć listę badanych dodaj przynajmniej jednego badanego.",
    },
    kebabMenu: {
      print: "Wydrukuj kontrakt",
      profile: "Przejdź do profilu badanego",
    },
  },
  AdminDashboard: {
    header: "Kartoteka klientów",
    emptyState: {
      header: "Nie dodano jeszcze kont klientów",
      info: "Aby zobaczyć listę klientów dodaj przynajmniej jedno konto.",
    },
    emptyStateUsers: {
      header: "Nie dodano jeszcze kont użytkowników",
      info: "Aby zobaczyć listę użytkowników dodaj przynajmniej jedno konto użytkownika.",
    },
    emptyStateLicense: {
      header: "Nie dodano jeszcze licencji",
      info: "Aby zobaczyć licencję dodaj ją.",
    },
    kebabMenu: {
      print: "Wydrukuj kontrakt",
      profile: "Przejdź do profilu badanego",
    },
    PsychologicalLabsList: {
      labels: {
        name: "Nazwa klienta",
        clientId: "ID klienta",
        status: "Status",
        psychCnt: "Liczba użytkowników",
        createDate: "Data utworzenia",
        expireDate: "Data ważności konta",
        details: "Szczegóły",
        usersCnt: "Liczba użytkowników",
        examineesCnt: "Liczba badanych",
        examinationsCnt: "Liczba badań",
        taskPerPage: "Wierszy na stronę:",
        all: "wszystkie",
        of: "z",
        moreThen: "więcej niż",
      },
      labStatus: {
        enabled: "aktywne",
        disabled: "nieaktywne",
      }
    },
    Licence: {
      labels: {
        methodologyList: "Dostępne metodyki",
        testsList: "Dostępne testy",
        licenseDate: "Data udzielenie licencji",
        expireDate: "Ważność licencji",
      },
      deletePopup: {
        headerText: "Czy na pewno chesz usunąć licencję?",
        contentText: "Po usunięciu licencji nie będzie możliwości przydzielania badań",
        confirmationText: "Tak, chcę usunąć licencję",
      },
    },
    PsychLab: {
      deletePopup: {
        headerText: "Czy na pewno chesz usunąć konto klienta?",
        contentText: "Po usunięciu konta klienta nie będzie możliwości przywrócenia danych",
        confirmationText: "Tak, chcę usunąć konto klienta",
      },
    },
    StatsList: {
      statFileName: "statystyki_badan.xlsx",
    },
    ActivitiesList: {
      labels: {
        date: "Data",
        action: "Czynność",
        executor: "Wykonawca",
        context: "Opis",
        status: "Status",
        errorMsg: "Opis błędu",
      },
      labStatus: {
        success: "ok",
        error: "błąd",
      }
    },
    UsersList: {
      deletePopup: {
        headerText: "Czy na pewno chcesz usunąć konto użytkownika?",
        contentText: "Po usunięciu konta użytkownika nie będzie możliwości przywrócenia go",
        confirmationText: "Tak, chcę usunąć konto użytkownika",
      },
      labels: {
        firstName: "Imię",
        lastName: "Nazwisko",
        email: "Email",
        examineeCnt: "Liczba uczestników",
        examinationCnt: "Liczba badań",
        isActive: "Status",
        isStaff: "Rola",
      },
      labStatus: {
        enabled: "aktywny",
        disabled: "nieaktywny",
        adminRole: "administartor",
        superUserRole: "super admin",
        userRole: "użytkownik",
        userBaseRole: "rejestrator",
      },
      toolTip: {
        edit: "edytuj",
        delete: "usuń",
      }
    },
    PsychLabManagment: {
      breadcrumbs: {
        add: "Dodaj konto klienta",
        edit: "Edytuj konto klienta",
        addLicense: "Dodaj licencję",
        editLicense: "Edytuj licencję",
        addUser: "Dodaj konto użytkownika",
        editUser: "Edytuj konto użytkownika",
        dashboard: "Panel użytkownika",
        psychLabs: "Kartoteka klientów",
      },
      header: {
        add: "Dodaj konto klienta",
        edit: "Edytuj konto klienta",
        addUser: "Dodaj konto użytkownika",
        editUser: "Edytuj konto użytkownika",
        addLicense: "Dodaj licencję",
        editLicense: "Edytuj licencję",
      },
      PsychLabView: {
        labels: {
          details: "Szczegóły",
          download: "Pobierz",
          edit: "Edytuj",
          generate: "Wygeneruj",
          name: "Nazwa testu",
          nameFile: "Nazwa pliku",
          print: "Wydrukuj",
          result: "Wynik",
          type: "Rodzaj dokumentu",
        },
        tabs: {
          psychLabData: "Dane klienta",
          license: "Licencja",
          users: "Użytkownicy",
          activityLogs: "Rejestr czynności",
          activityLogsFile: "rejestr_czynnosci",
          activityId: "id",
          activityDate: "data",
          activityAction: "nazwa czynności",
          activityStatus: "status",
          activityContext: "opis",
          activityExecutor: "wykonawca",
          stats: "Statystyki",
          theme: "Wygląd",
        },
      }
    },
  },
  ExaminationAssignmentView: {
    header: "Przydziel badanie",
    labels: {
      chooseExaminationType: "Wybierz typ badania",
      chooseLawBase: "Wybierz podstawę prawną badania",
      description: "Opis",
      legalBase: "Podstawa prawna badania",
      reason: "Cel badania",
      suggestedTestMethods: "PROPONOWANE METODYKI",
      methodologyTest2Safe: "TEST2SAFE - metodyka badań psychologicznych dla pracowników ochrony i detektywów",
      methodologyTest2Drive: "TEST2DRIVE - metodyka przeprowadzania badań kierowców w ramach psychologii transportu",
      methodologyTest2Arms: "TEST2ARMS - metodyka badań psychologicznych, działalność koncesjonowana",
      methodologyTest2Work: "TEST2WORK - metodyka badań psychologicznych w medycynie pracy",
    },
    popup: {
      header: "Niektóre testy rekomendowane dla wybranej metodyki zostały wyłączone.",
      content: "Czy na pewno chcesz pominąć te testy?",
      checkbox: "Zapamiętaj wybór dla wszystkich badanych",
    },
    license: "Test niedostępny w wykupionej licencji",
  },
  PsychologicalTests: {
    psychomotor: "Testy psychomotoryczne",
    cognitive: "Testy poznawcze",
    personality: "Testy osobowościowe",
  },
  ExamineeList: {
    labels: {
      contract: "Kontrakt",
      examine: "Badanie",
      examineLastDate: "Data ostatniego badania",
      allFinishExam: "Przydzielone / zakończone",
      firstName: "Imię",
      folder: "Folder",
      lastName: "Nazwisko",
      peselSlashIdentifier: "PESEL / Identyfikator",
      profile: "Zobacz profil",
      added: "Dodano",
    },
  },
  ExaminationList: {
    withoutLegalBasis: "Badanie bez podstawy prawnej",
    labels: {
      assign: "Przydzielono",
      assignDate: "Data przypisania",
      create: "Utworzono",
      createDate: "Data utworzenia",
      contract: "Kontrakt",
      documentation: "Dokumentacja",
      end: "Zakończono",
      endDate: "Data zakończenia",
      interview: "Wywiad",
      identificator: "ID badania",
      language: "Język",
      nameArchiveExamination: "Nazwa baterii",
      nameExamination: "Nazwa badania",
      start: "Rozpoczęto",
      startDate: "Data rozpoczęcia",
    },
    kebabMenu: {
      delete: "Usuń badanie",
    },
    deletePopup: {
      headerText: "Czy na pewno chcesz usunąć badanie?",
      contentText: "Po usunięciu badania nie będzie możliwości przywrócenia go",
      confirmationText: "Tak, chcę usunąć badanie",
    },
    toolTip: {
      contract: "Generuj kontrakt",
      interview: "Wywiad",
      documentation: "Dokumentacja",
      delete: "Usuń",
    },
  },
  ArchiveExaminationList: {
    emptyState: {
      info: "Brak badań archiwalnych przypisanych do danego badanego",
    },
  },
  ExaminationView: {
    header: "Dokumentacja",
    breadcrumbs: {
      archive: "Badania archiwalne",
    },
    details: {
      createDate: "Utworzenie badania",
      endDate: "Zakończenie badania",
      identificator: "ID badania",
      language: "Język",
      name: "Nazwa badania",
      startDate: "Data rozpoczecia badania",
      type: "Nazwa baterii",
    },
    protocols: {
      language: "Język protokołów",
      testProtocol: "Protokół testu",
    },
    labels: {
      details: "Szczegóły",
      download: "Pobierz",
      edit: "Edytuj",
      generate: "Wygeneruj",
      name: "Nazwa testu",
      nameFile: "Nazwa pliku",
      print: "Wydrukuj",
      result: "Wynik",
      type: "Rodzaj dokumentu",
    },
    tabs: {
      documentation: "Dokumentacja",
      protocols: "Protokoły",
      reports: "Raporty",
    },
    common: {
      unavailableInLanguageOption: "Niedostępne w wybranej wersji językowej.",
    },
    aimsKind: {
      aimKind_1: "Opinia psychologiczna - detektywi",
      aimKind_3: "Orzeczenie - pracownik ochrony fizycznej",
      aimKind_8: "Orzeczenie odwoławcze - pracownik ochrony fizycznej",
      aimKind_9: "Orzeczenie - pozwolenie na broń",
      aimKind_10: "Orzeczenie  - działalność koncesjonowana o przeznaczeniu wojskowym lub policyjnym",
      aimKind_11: "Orzeczenie  - działalność koncesjonowana o przeznaczeniu cywilnym",
      aimKind_12: "Orzeczenie odwoławcze  - działalność koncesjonowana o przeznaczeniu cywilnym",
      aimKind_13: "Orzeczenie - medycyna pracy",
      aimKind_14: "Orzeczenie - medycyna pracy górnictwo i ratownictwo górnicze",
      aimKind_15: "Orzeczenie - medycyna pracy licencja maszynisty",
      aimKind_16: "Orzeczenie - kierowca kategoria C1, C1+E, C, C+E, D1, D1+E, D i D+E lub do kierowania tramwajem",
      aimKind_17: "Orzeczenie - kierowca przywrócenie uprawnień kategoria AM, A1, A2, A, B1, B, B+E i T",
      aimKind_18: "Orzeczenie - kierowca przywrócenie uprawnień przeciwskazania psychologiczne",
      aimKind_19: "Orzeczenie - przedłużenie uprawnień kategoria C1, C1+E, C, C+E, D1, D1+E, D i D+E lub do kierowania tramwajem",
      aimKind_20: "Orzeczenie - kierowca badanie powypadkowe",
      aimKind_21: "Orzeczenie - kierowca badanie po spożyciu alkoholu lub środków działających podobnie",
      aimKind_22: "Orzeczenie - kierowca badanie po przekroczeniu 24 pkt",
      aimKind_23: "Orzeczenie - kierowca badanie po co najmniej 2 wykroczeń",
      aimKind_24: "Orzeczenie - kierowca badanie przeciwskazania psychologiczne",
      aimKind_25: "Orzeczenie - kierowca pojazdu uprzywilejowanego",
      aimKind_26: "Orzeczenie - kierowca badanie powypadkowe (skierowanie przez starostę)",
      aimKind_27: "Orzeczenie - wykonywanie czynności instruktora",
      aimKind_28: "Orzeczenie - wykonywanie czynności egzaminatora",
      aimKind_29: "Orzeczenie - kierowca uczeń lub kandydat na ucznia szkoły ponadpodstawowej kategoria C lub C+E ",
      aimKind_30: "Orzeczenie - kierowca słuchacz lub kandydat na słuchacza kursu zawodowego kategoria C lub C+E ",
      aimKind_31: "Orzeczenie - zawód kierowcy skierowany przez pracodawcę",
      aimKind_32: "Orzeczenie - zawód kierowcy samozatrudniony",
    },
  },
  ExamineeView: {
    anonymity: "Badany anonimowy",
    userData: {
      defaultAnonFirstName: "Badany",
      defaultAnonLastName: "Anonimowy",
      pesel: "PESEL",
      sex: "Płeć",
      birthDate: "Data urodzenia",
      address: "Adres",
      education: "Wykształcenie",
      drivingLicense: "Kategoria prawa jazdy",
      identityCard: "Dokument tożsamości",
      serialNumber: "Seria i numer",
      identificator: "Identyfikator",
      drivingLicenseIssuer: "Podmiot wydający prawo jazdy",
      examineName: "Imię i nazwisko",
    },
    drivingLicense: {
      am: "AM",
      a1: "A1",
      a2: "A2",
      a: "A",
      b1: "B1",
      b: "B",
      be: "B+E",
      c: "C",
      c1: "C1",
      c1e: "C1+E",
      ce: "C+E",
      d: "D",
      d1: "D1",
      d1e: "D1+E",
      de: "D+E",
      t: "T",
      tramwaj: "TRAMWAJ",
    },
    tabs: {
      archive: "Badania archiwalne",
      data: "Dane badanego",
      examination: "Badania",
    },
    kebabMenu: {
      edit: "Edytuj dane badanego",
      delete: "Usuń badanego",
    },
    emptyState: {
      header: "Brak badań przypisanych do danego badanego",
      info: "Aby zobaczyć listę badań, przydziel przynajmniej jedno badanie.",
    },
    translations: {
      passport: "paszport",
      id_card: "dowód osobisty",
      male: "mężczyzna",
      female: "kobieta",
      primary: "podstawowe",
      lowerSecondary: "gimnazjalne",
      basicVocational: "zasadnicze zawodowe",
      basicIndustry: "zasadnicze branżowe",
      secondaryIndustry: "średnie branżowe",
      secondary: "średnie",
      higher: "wyższe",
    },
  },
  ExamineeManagment: {
    breadcrumbs: {
      add: "Dodaj badanego",
      edit: "Edytuj badanego",
      dashboard: "Panel użytkownika",
      directories: "Kartoteka badanych",
    },
    header: {
      add: "Dodaj osobę badaną",
      edit: "Edytuj dane osoby badanej",
    },
  },
  Forms: {
    adminFields: {
      methodologyTlt: "Metodyka",
      testPsychMotorTlt: "testy psychomotoryczne",
      testCogTlt: "testy poznawcze",
      testPersonTlt: "testy osobowościowe",
      address: "Ulica i numer domu",
      name: "Nazwa",
      firstName: "Imię",
      lastName: "Nazwisko",
      email: "Email",
      password: "Hasło",
      pin: "Kod PIN",
      isActive: "Status konta",
      isStaff: "Rola",
      dateExpire: "Data ważności konta",
      dateLicExpire: "Data ważności licencji",
      licMethodologies: "Dostępne metodyki",
      licTests: "Dostępne testy",
      city: "Miejscowość",
      postalCode: "kod pocztowy",
      clientId: "ID klienta",
      cin: "Numer wpisu",
      tin: "NIP",
      theme: "Wygląd",
      examineesCnt: "Liczba badanych",
      usersCnt: "Liczba użytkowników",
      dateFrom: "zakres od",
      dateTo: "zakres do",
      activityName: "czynność",
      activityDetails: "Szczegóły",
      status: "status",
    },
    adminOptions: {
      theme: {
        optimisTheme: "optimis",
        defaultTheme: "default",
      },
      status: {
        active: "aktywne",
        disabled: "nieaktywne",
      },
      admin: {
        admin: "administrator",
        user: "użytkownik",
        baseuser: "rejestrator",
      },
    },
    adminSnackbar: {
      required: "Należy uzupełnić wszystkie pola obowiązkowe.",
      email_unique_error: "Podany adres email już istnieje w bazie!",
      email_wrong_error: "Podany adres email jest niepoprawny!",
    },
    fields: {
      address: "Ulica i numer domu",
      additionalInfo: "Informacje dodatkowe",
      appealAuthority: "Nazwa instytucji",
      appealDeadline: "Data",
      birthDate: "Data urodzenia",
      city: "Miasto",
      country: "Kraj",
      diffrentIdentificationNumber: "Inny identyfikator",
      directory: "Folder",
      documentDate: "Data wystawienia",
      drivingLicense: "Kategoria prawa jazdy",
      drivingLicenseIssuer: "Podmiot wydający prawo jazdy",
      education: "Wykształcenie",
      identificationNumber: "PESEL",
      identityDocumentType: "Dokument tożsamości",
      identityDocumentNumber: "Seria i numer",
      interval: "Wybierz przedział ufności",
      judgmentNumber: "Numer orzeczenia",
      languageReport: "Wybierz język raportu",
      legalBasis: "Podstawa prawna badania",
      months: "miesiące",
      name: "Imię",
      nextExamination: "Data kolejnego badania",
      postalCode: "Kod pocztowy",
      recipient: "Wybierz odbiorcę",
      sex: "Płeć",
      standard: "Wybierz normę",
      surname: "Nazwisko",
      years: "Lata",
      year: "Rok",
      examineeRegistryId: "Numer w rejestrze osób badanych",
      age: "Wiek",
      date: "Data",
      institutionName: "Nazwa instytucji",
      lang: "Język",
    },
    options: {
      activitiesStatus: {
        ok: "ok",
        user_error: "błąd użytkownika",
        server_error: "błąd serwera",
      },
      activities: {
        ALL: "wszytskie",
        AUDIT_ERROR: "Błąd zapisu aktywności",
        ALL_ITEMS: "Wszystkie aktywności",
        APP_REGISTRATION: "Zarejestrowanie aplikacji",
        TEST_RESULT_SUBMITTED: "Przesłanie wyników badania",
        CONSOLE_PIN_VERIFICATION: "Weryfikacja PIN na konsoli użytkownika",
        SAVE_AGREEMENTS: "Zapisanie zgód na badanie i przetwarzanie danych osobowych",
        TEST_SKIPPED: "Pominięcie testu na konsoli",
        EXAMINATION_STARTED: "Rozpoczęcie badania",
        EXAMINATION_TERMINATED: "Zakończenie badania",
        ADMIN_LOGGED_OUT: "Wylogowanie administratora",
        ADMIN_CHANGE_LAB_ID: "Zalogowanie administartora na konto klienta",
        USER_LOGGED_IN: "Zalogowanie użytkownika",
        USER_LOGGED_OUT: "Wylogowanie użytkownika",
        PASSWORD_CHANGE_REQUEST: "Żądanie zmiany hasła",
        PASSWORD_CHANGE: "Zmiana hasła",
        PASSWORD_CHANGE_EXPIRED: "Zmiana hasła po wygaśnięciu ważności hasła",
        PIN_CHANGE: "Zmiana numeru PIN",
        DIRECTORY_CREATED: "Utworzenie folderu",
        DIRECTORY_NAME_CHANGED: "Zmiana nazwy folderu",
        DIRECTORY_DELETED: "Usunięcie folderu",
        EXAMINEE_ADDED: "Dodanie badanego",
        EXAMINEE_DATA_CHANGE: "Zmiana danych badanego",
        EXAMINEE_DELETED: "Usunięcie danych badanego",
        EXAMINEE_MOVED_TO_DIFFERENT_DIRECTORY: "Przeniesienie danych badanego do innego folderu",
        EXAMINATION_ASSIGNED: "Przydzielenie badania",
        EXAMINATION_DELETED: "Usunięcie badania",
        EXAMINATION_REPORT_DOWNLOADED: "Pobranie dokumentów raportów",
        CONTRACT_DOWNLOADED: "Pobranie kontraktu",
        EXAMINATION_PROTOCOL_DOWNLOADED: "Pobranie protokołu badania",
        EXAMINATION_PROTOCOLS_ZIP_DOWNLOADED: "Pobranie protokołów badania ZIP",
        INTERVIEW_CHANGED: "Zmiana wywiadu",
        INTERVIEW_DOWNLOADED: "Pobranie wywiadu",
        ADMIN_LICENCE_ADDED: "Dodanie licencji dla konta klienta",
        ADMIN_LICENCE_CHANGED: "Zmiana licencji konta klienta",
        ADMIN_LICENCE_DELETED: "Usunięcie licencji konta klienta",
        ADMIN_PSYCHOLOGICAL_LAB_ADDED: "Dodanie konta klienta",
        ADMIN_PSYCHOLOGICAL_LAB_DELETED: "Usunięcie konta klienta",
        ADMIN_PSYCHOLOGICAL_LAB_CHANGED: "Modyfikacja danych konta klienta",
        ADMIN_USER_ADDED: "Dodanie użytkownika do konta klienta",
        ADMIN_USER_DELETED: "Usunięcie użytkownika z konta klienta",
        ADMIN_USER_CHANGED: "Zmiana danych użytkownika do konta klienta",
        ADMIN_LOGGED_IN: "Zalogowanie administratora",
      },
      norms: {
        norm_1: "OPT_1.0.PL",
        norm_2: "OPT_1.0.UA",
        norm_3: "OPT_1.0.RU",
      },
      bool: {
        true: "Tak",
        false: "Nie",
      },
      education: {
        primary: "Podstawowe",
        lowerSecondary: "Gimnazjalne",
        secondary: "Średnie",
        basicVocational: "Zasadnicze zawodowe",
        basicIndustry: "Zasadnicze branżowe",
        secondaryIndustry: "Średnie branżowe",
        higher: "Wyższe",
      },
      identityDocument: {
        idCard: "Dowód osobisty",
        passport: "Paszport",
      },
      sex: {
        female: "Kobieta",
        male: "Mężczyzna",
      },
      lang: {
        polish: "polski",
        english: "angielski",
        espaniol: "hiszpański",
        mexico: "meksykański",
        russian: "rosyjski",
        ukrain: "ukraiński",
      },
      country: {
        poland: "Polska",
        austria: "Austria",
        bulgaria: "Bułgaria",
        croatia: "Chorwacja",
        cyprus: "Cypr",
        czechRepublic: "Republika Czeska",
        denmark: "Dania",
        estonia: "Estonia",
        finland: "Finlandia",
        france: "Francja",
        greece: "Grecja",
        spain: "Hiszpania",
        netherlands: "Holandia",
        ireland: "Irlandia",
        lithuania: "Litwa",
        luxembourg: "Luksemburg",
        latvia: "Łotwa",
        malta: "Malta",
        germany: "Niemcy",
        portugal: "Portugalia",
        romania: "Rumunia",
        slovakia: "Słowacja",
        slovenia: "Słowenia",
        sweden: "Szwecja",
        hungary: "Węgry",
        italy: "Włochy",
        afghanistan: "Afganistan",
        albania: "Albania",
        angola: "Angola",
        saudiArabia: "Arabia Saudyjska",
        argentina: "Argentyna",
        armenia: "Armenia",
        azerbaijan: "Azerbejdżan",
        bangladesh: "Bangladesz",
        belarus: "Białoruś",
        bolivia: "Boliwia",
        bosniaAndHerzegovina: "Bośnia i Hercegowina",
        brazil: "Brazylia",
        chile: "Chile",
        china: "Chiny",
        chad: "Chad",
        montenegro: "Czarnogóra",
        democraticRepublicOfTheCongo: "Demokratyczna Republika Konga",
        dominicanRepublic: "Republika Dominikany",
        ecuador: "Ekwador",
        ethiopia: "Etiopia",
        gambia: "Gambia",
        ghana: "Ghana",
        georgia: "Gruzja",
        guatemala: "Gwatemala",
        honduras: "Honduras",
        india: "Indie",
        indonesia: "Indonezja",
        iran: "Iran",
        iceland: "Islandia",
        israel: "Izrael",
        jamaica: "Jamajka",
        japan: "Japonia",
        yemen: "Jemen",
        jordan: "Jordania",
        cambodia: "Kambodża",
        cameroon: "Kamerun",
        qatar: "Katar",
        kazakhstan: "Kazachstan",
        congo: "Kongo",
        southKorea: "Korea Południowa",
        laos: "Laos",
        northernMacedonia: "Północna Macedonia",
        madagascar: "Madagaskar",
        malaysia: "Malezja",
        mali: "Mali",
        mauritania: "Mauritania",
        mexico: "Meksyk",
        myanmar: "Myanmar",
        moldova: "Moldova",
        mozambique: "Mozambik",
        namibia: "Namibia",
        niger: "Niger",
        nigeria: "Nigeria",
        nicaragua: "Nikaragua",
        norway: "Norwegia",
        oman: "Oman",
        panama: "Panama",
        paraguay: "Paragwaj",
        peru: "Peru",
        centralAfricanRepublic: "Republika Środkowoafrykańska",
        russia: "Rosja",
        rwanda: "Rwanda",
        elSalvador: "Salwador",
        senegal: "Senegal",
        serbia: "Serbia",
        somalia: "Somali",
        unitedStatesOfAmerica: "Stany Zjednoczone Ameryki",
        sudan: "Sudan",
        southSudan: "Południowy Sudan",
        switzerland: "Szwajcaria",
        tajikistan: "Tadżykistan",
        thailand: "Tajlandia",
        turkey: "Turcja",
        uganda: "Uganda",
        ukraine: "Ukraina",
        uruguay: "Urugwaj",
        venezuela: "Wenezuela",
        unitedKingdom: "Zjednoczone Królestwo",
        vietnam: "Wietnam",
        zambia: "Zambia",
        unitedArabEmirates: "Zjednoczone Emiraty Arabskie",
        zimbabwe: "Zimbabwe",
      },
      recipient: {
        examinee: "Osoba badana",
        psychologist: "Psycholog",
        specialist: "Specjalista",
      },
      languagesDocumentation: {
        pl: "polski",
        en: "angielski",
      },
    },
    extraFields: "Dane uzupełniające",
    requiredFields: "Dane obowiązkowe",
    anonymous: "Badanie anonimowe",
    snackbar: {
      peselLength: "PESEL musi składać się z 11 cyfr",
      peselNumber: "PESEL może składać się tylko z liczb",
      required: "Należy uzupełnić wszystkie pola obowiązkowe.",
    },
  },
  FormValidation: {
    max: "Nazwa nie może mieć więcej niż 128 znaków",
    required: "To pole jest obowiązkowe",
    Pin: {
      length: "Musi zawierać 4 cyfry",
    },
    Password: {
      length: "Minimum 8 znaków",
      characters: "Musi zawierać wielką literę, małą literę, znak specjalny oraz cyfrę",
    },
  },
  Helpdesk: {
    header: "Pomoc",
    subheader: "Help desk",
    phoneNo: "tel.",
    mobile: "tel.",
    help: "Pomoc zdalna",
    contact: "Kontakt",
    documents: {
      header: "Dokumenty pomocy",
      labels: {
        download: "Pobierz",
        name: "Nazwa dokumentu",
      },
    },
  },
  Interview: {
    header: "Wywiad",
    inputAdditionalInformation: "Wpisz dodatkowe informacje",
    inputHoursOfWork: "Wpisz ilość godzin pracy w ostatniej dobie",
    years: "Lata",
    months: "Miesiące",
    hours: "Godziny",
    tabs: {
      currentPsychophysicalState: "Sytuacja zdrowotna",
      socialAndFamilySituation: "Sytuacja społeczna i rodzinna",
      observation: "Obserwacja",
      drivingVehicles: "Kierowanie pojazdami",
      occupationalMedicine: "Medycyna pracy",
      securityAndDetectives: "Detektywi i pracownicy ochrony",
      licensedActivities: "Działalność koncesjonowana",
    },
    questions: {
      questionAnswers: {
        yes: "tak",
        no: "nie",
      },
      questionTexts: {
        when: "Kiedy ? ",
        sinceWhen: "Od kiedy ?",
        forHowLong: "Do kiedy ?",
        why: "Z jakiego powodu ?",
        whatAreTheConsequences: "Jakie były skutki zdrowotne ?",
        describeTheCircumStancesOfUsage: "Opisz okoliczności tego użycia",
      },
      methodologies: {
        currentPsychophysicalState: {
          headers: {
            currentState: "Aktualny stan psychofizyczny",
            criticalEvents: "Zdarzenia krytyczne",
          },
          questionExtendedTexts: {
            commonText: "Opisz okoliczności tego zdarzenia/sytuacji",
          },
          questionTexts: {
            question1: "Czy osoba badana spożywała alkohol w ciągu ostatnich 48 godzin?",
            question2: "Czy osoba badana w ciągu ostatniej lub przedostatniej nocy pracowała zawodowo?",
            question3: "Liczba godzin pracy w ostatniej dobie",
            question4: "Uraz głowy wymagający pomocy medycznej",
            question5: "Utrata przytomności",
            question6: "Pobyt w szpitalu",
            question7: "Czy kiedykolwiek miało miejsce lub aktualnie ma miejsce leczenie z powodu/" +
              " czy pojawiło się kiedykolwiek rozpoznanie następujących chorób:",
            question7a: "a. chorób układu pokarmowego (choroby wrzodowej żołądka lub dwunastnicy, zaburzeń czynności jelit itp.) ",
            question7b: "b. chorób serca i układu krążenia (choroby wieńcowej, podwyższenia ciśnienia tętniczego krwi)",
            question7c: "c. zaburzeń słuchu lub wzroku (nie dotyczy noszenia okularów korekcyjnych)",
            question7d: "d. cukrzycy",
            question7e: "e. epilepsji ",
            question7f: "f. chorób psychicznych (depresja, nerwica," +
              " leczenie psychiatryczne (dopytać o leczenie psychiatryczne członka bliskiej rodziny)",
            question8: "Używanie leków na stałe (dopytać o to, czy zna /" +
              " ma świadomość działań ubocznych i dopytać o inne leki wpływające na funkcje psychiczne)",
            question9: "Problemy ze snem",
            question10: "Korzystanie z pomocy:",
            question10a: "a. psychologa",
            question10b: "b. lekarza psychiatry",
            question10c: "c. neurologa",
            question11: "Orzeczenie niepełnosprawności",
            question12: "Problemy z powodu picia alkoholu",
            question13: "Pobyt w izbie wytrzeźwień",
            question14: "Używanie środków odurzających/psychoaktywnych/narkotyki",
            question15: "Sugestie lekarza lub kogoś bliskiego podjęcia leczenia ze względu na nerwowość," +
              " roztargnienie lub problemy z pamięcią lub orientacją",
          },
        },
        socialAndFamilySituation: {
          questionTexts: {
            question1: "Poważny konflikt lub problem związany z pracą " +
              "(np. wypadek w pracy, nagana, sąd pracy, zwolnienie dyscyplinarne, mobbing)",
            question2: "Problemy na etapie kształcenia " +
              "(np. powtarzanie klasy, wyrzucenie ze szkoły, zawieszenie w prawach ucznia)",
            question3: "Poważny konflikt rodzinny lub problemy w sytuacjach prywatnych " +
              "(np. sprawy sądowe, udział w bójkach, można dopytać o status związku, dzieci) ",
            question4: "Sytuacja bezpośredniego zagrożenia życia (np. pożar, powódź i inne katastrofy)",
            question5: "Doznanie aktu przemocy fizycznej na własnej osobie (np. napaść, kradzież, pobicie)",
            question6: "Zaangażowanie w działania organizacji społecznych (np. wolontariat)",
            question7: "Karalność (np. otrzymanie mandatu, wyrok sądu) ",
          },
        },
        observation: {
          questionTexts: {
            question1: "Kontakt z rozmówcą",
            question2: "Język i retoryka wypowiedzi",
            question3: "Nastrój i nastawienie",
            question4: "Opanowanie i kontrola zachowań i emocji",
          },
          questionAnswers: {
            question1a: "Nawiązuje bardzo dobry kontakt z rozmówcą; utrzymuje kontakt wzrokowy;" +
              " zachowuje się pewnie i swobodnie, nie pozwala się wytrącić z równowagi czy zniecierpliwić," +
              " zachowuje się spontanicznie i naturalnie. ",
            question1b: "Nawiązuje kontakt z rozmówcą; utrzymuje kontakt wzrokowy; zachowuje się naturalnie," +
              " choć widoczne są oznaki pewnego zdenerwowania. ",
            question1c: "Zachowuje się w sposób zdystansowany, unika kontaktu wzrokowego; zachowuje się nienaturalnie," +
              " małe bodźce powodują wytrącenie z równowagi, widoczne są wyraźne oznaki zdenerwowania i zniecierpliwienia. ",
            question2a: "Formułuje wypowiedzi używając poprawnego języka," +
              " buduje jasne, czytelne zdania, utrzymuje logiczną strukturę wypowiedzi," +
              " wykorzystuje bogaty zasób słownictwa.  ",
            question2b: "Formułuje poprawnie wypowiedzi, " +
              "generalnie utrzymuje ich logiczną strukturę; zasób słów przeciętny, ale poprawny. ",
            question2c: "Formułuje krótkie, zdawkowe wypowiedzi, często niespójne lub niejasne;" +
              " używa niepoprawnych zwrotów i sformułowań; zdania są bardzo proste," +
              " słownictwo bardzo ubogie, występują błędy gramatyczne. ",
            question3a: "Obserwowalny bardzo pogodny, optymistyczny nastrój; zadowolenie z siebie i swojej sytuacji życiowej. ",
            question3b: "Obserwowalny umiarkowany nastrój, dość pozytywne nastawienie i generalnie optymistyczne spojrzenie w przyszłość. ",
            question3c: "Obserwowalne nieproporcjonalnie silne reakcje emocjonalne," +
              " wyraźna skłonność do pesymistycznego odbioru świata; okazywanie niezadowolenia. ",
            question4a: "W trakcie badania adekwatnie kontroluje swoje zachowanie i emocje," +
              " adekwatnie dostosowuje się do pojawiających się okoliczności. ",
            question4b: "W trakcie badania na ogół kontroluje swoje zachowanie i emocje;" +
              " najczęściej adekwatnie reaguje na pojawiające się okoliczności. ",
            question4c: "W trakcie badania obserwowalne trudności z kontrolowaniem zachowań i emocji;" +
              " pojawiają się nieadekwatne zachowania lub emocje. ",
          },
        },
        drivingVehicles: {
          headers: {
            drivingCharacteristics: "Charakterystyka przejeżdżanych tras",
          },
          questionTexts: {
            question1: "Staż w prowadzeniu pojazdów ogólnie",
            question2: "Staż w prowadzeniu pojazdów na stanowisku kierowcy",
            question3: "Liczba rocznie przejechanych kilometrów",
            question4: "Liczba godzin spędzanych na prowadzeniu samochodu",
            question5: "Długość tras ",
            question6: "Różnorodność",
            question7: "Pory dnia ",
            question8: "Zmiennik",
            question9: "Obszar",
            question10: "Zatrzymanie lub odebranie prawa jazdy",
            question11: "Orzeczenie niezdolności do prowadzenia pojazdów",
            question12: "Kara za wykroczenie lub przestępstwo drogowe",
            question13: "Udział jako kierowca w wypadku samochodowym",
            question14: "Uczestnictwo jako kierowca w kolizji drogowej",
            question15: "Posiadane dodatkowe uprawnienia i licencje",
          },
          questionAnswers: {
            question3a: "mniej niż 3 000 km rocznie",
            question3b: "między 3 000 – 15 000 km rocznie",
            question3c: "między 15 000 - 70 000 km rocznie",
            question3d: "powyżej 70 000 km rocznie",
            question3e: "trudno powiedzieć",

            question4a: "mniej niż 5 godzin tygodniowo",
            question4b: "między 5 - 15 godzin tygodniowo",
            question4c: "między 15 - 40 godzin tygodniowo",
            question4d: "powyżej 40 godzin tygodniowo",
            question4e: "trudno powiedzieć",

            question5a: "jeżdżę przeważnie na krótkich trasach",
            question5b: "jeżdżę przeważnie na długich trasach",
            question5c: "jeżdżę zarówno na długich jak i na krótkich trasach",

            question6a: "jeżdżę zazwyczaj na stałych trasach",
            question6b: "jeżdżę najczęściej na różnych trasach",

            question7a: "jeżdżę najczęściej nocą",
            question7b: "jeżdżę zwykle w ciągu dnia",
            question7c: "jeżdżę zarówno w ciągu dnia jak i nocą",

            question8a: "jeżdżę głównie samodzielnie bez zmiennika",
            question8b: "jeżdżę najczęściej ze zmiennikiem",

            question9a: "jeżdżę głównie w mieście",
            question9b: "jeżdżę głownie poza miastem",

            question13a: "jako sprawca/współsprawca",
            question13b: "jako poszkodowany",
            question13c: "nie dotyczy",

            question14a: "jako sprawca/współsprawca",
            question14b: "jako poszkodowany",
            question14c: "nie dotyczy",

            question15a: "przewóz materiałów niebezpiecznych",
            question15b: "przewóz osób",
            question15c: "instruktor prawa jazdy",
            question15d: "egzaminator prawa jazdy",
            question15e: "brak",
          },
        },
        occupationalMedicine: {
          headers: {
            workInformation: "Informacje dotyczące pracy zawodowej",
            workCourseInformation: "Przebieg pracy zawodowej",
            companyCarDrivers: "Kierowcy samochodów służbowych",
            workingAtHeights: "Praca na wysokości",
            forkliftOperators: "Operatorzy wózków widłowych",
            minersAndMinerLifeguards: "Górnicy i ratownicy górniczy",
            trainDriver: "Maszyniści",
          },
          questionTexts: {
            question1: "Zawód wyuczony",
            question2: "Zawód wykonywany",
            question3: "Miejsce pracy / szkoła",
            question4: "Stanowisko / wykonywane czynności",
            question5: "Czynniki szkodliwe/uciążliwe występujące na stałe w miejscu pracy",
            question5f: "Jakie ?",
            question5g: "Jakie ?",
            question6: "Staż pracy ogółem",
            question7: "Staż pracy na obecnym stanowisku",
            question8: "Stwierdzono chorobę zawodową",
            question8a: "Kiedy? Z jakiego powodu?",
            question9: "Przyznano świadczenie rentowe",
            question9a: "Z jakiego powodu? Od kiedy? Do kiedy?",
            question10: "Osoba badana uległa wypadkowi w pracy",
            question10a: "Kiedy? Jakie były skutki zdrowotne?",
            question11: "Orzeczono niepełnosprawność",
            question11a: "Z jakiego powodu? Od kiedy? Do kiedy?",
            question12: "Staż w prowadzeniu pojazdów ogólnie",
            question13: "Liczba rocznie przejechanych kilometrów",
            question14: "Liczba godzin spędzanych na prowadzeniu samochodu",
            question15: "Orzeczenie niezdolności do prowadzenia pojazdów",
            question16: "Zatrzymanie lub odebranie prawa jazdy",
            question17: "Kara za wykroczenie lub przestępstwo drogowe",
            question18: "Udział jako kierowca w wypadku samochodowym ",
            question19: "Uczestnictwo jako kierowca w kolizji drogowej",
            question20: "Posiadanie uprawnień do wykonywania pracy na wysokości",
            question21: "Wykonywanie prac na wysokości (jeśli tak, dopytać, ile metrów nad ziemią)",
            question22: "Subiektywne odczuwanie lęku podczas przebywania na wysokości",
            question23: "Wypadek podczas pracy na wysokości",
            question23a: "Czy nastąpiło uszkodzenie mienia lub zdrowia?",
            question24: "Posiadanie uprawnień do wykonywania pracy operatora wózka widłowego",
            question25: "Wykonywanie prac związanych z obsługą i prowadzeniem wózka widłowego",
            question26: "Wypadek podczas obsługi lub prowadzenia wózka widłowego",
            question26a: "Czy nastąpiło uszkodzenie mienia lub zdrowia?",
          },
          questionAnswers: {
            question5a: "Praca monotonna",
            question5b: "Stały duży dopływ informacji",
            question5c: "Wysoki poziom hałasu",
            question5d: "Praca z dużym obciążeniem psychicznym (odpowiedzialność i decyzyjność)",
            question5e: "Promieniowanie jonizujące ",
            question5f: "Czynniki chemiczne",
            question5g: "Inne",

            question13a: "mniej niż 3 000 km rocznie",
            question13b: "między 3 000 – 15 000 km rocznie",
            question13c: "między 15 000 - 70 000 km rocznie",
            question13d: "powyżej 70 000 km rocznie",
            question13e: "trudno powiedzieć",

            question14a: "mniej niż 5 godzin tygodniowo",
            question14b: "między 5 - 15 godzin tygodniowo",
            question14c: "między 15 - 40 godzin tygodniowo",
            question14d: "powyżej 40 godzin tygodniowo",
            question14e: "trudno powiedzieć",

            question18a: "jako sprawca/współsprawca",
            question18b: "jako poszkodowany",
            question18c: "nie dotyczy",

            question19a: "jako sprawca/współsprawca",
            question19b: "jako poszkodowany",
            question19c: "nie dotyczy",
          },
        },
        licensedActivity: {
          questionTexts: {
            question1: "Staż pracy ogółem w zakładzie pracy zajmującym " +
              "się działalnością koncesjonowaną",
            question2: "Stanowisko/ wykonywane czynności",
            question3: "Staż pracy na obecnym stanowisku w " +
              "zakładzie pracy zajmującym się działalnością koncesjonowaną",
            question4a: "Udział w wypadku w miejscu pracy",
            question4b: "Jakie były przyczyny, konsekwencje wypadku - czy nastąpiło uszkodzenie mienia, zdrowia?",
          },
        },
        detectivesSecurityGuardsAndWeaponPermits: {
          questionTexts: {
            question1: "Podstawowe cele wykorzystania broni:",
            question1g: "Jakie ?",
            question2: "Czy kiedykolwiek miało miejsce użycie broni " +
              "lub innych środków przymusu bezpośredniego?",
            question3: "Jak często miało miejsce użycie broni w ciągu ostatnich 5 lat?",
          },
          questionAnswers: {
            question1a: "do celów zawodowych (detektyw, pracownik ochrony)",
            question1b: "do celów łowieckich",
            question1c: "do celów sportowych",
            question1d: "do użytku osobistego",
            question1e: "do celów muzealnych, pamiątkowych, naukowych",
            question1f: "inne",
          },
        },
      },
    },
  },
  Language: { pl: "PL", ru: "RU", uk: "UK" },
  SearchEngine: {
    header: "Wyszukaj badanych",
    placeholderMobile: "Wyszukaj",
    placeholderDesktop: "Imię, nazwisko, PESEL/Identyfikator, data przydzielenia badania (RRRR-MM-DD)",
    noResults: "Brak wyników wyszukiwania",
    noResultsForFilter: "Nie znaleziono żadnych badanych spełniających wprowadzone wymagania.",
    searchForExaminees: "Wyszukaj badanych",
    enterFilter: "Wyszukiwać możesz po imieniu, nazwisku, numerze PESEL/Identyfikator\n" +
      "lub po dacie przydzielenia badania \n" +
      "w formacie RRRR-MM-DD.",
  },
  AdminSearchEngine: {
    header: "Wyszukaj klientów",
    placeholderMobile: "Wyszukaj",
    placeholderDesktop: "nazwa, ID, adres, NIP, data utworznenia, data ważności konta klienta w formacie RRRR-MM-DD, imię, nazwisko, email użytkownika, data ważności licencji, metodyka, test",
    noResults: "Brak wyników wyszukiwania",
    noResultsForFilter: "Nie znaleziono żadnych klientów spełniających wprowadzone wymagania.",
    searchForExaminees: "Wyszukaj klientów",
    enterFilter: "Wyszukiwać możesz po nazwie, ID, adresie, NIP, dacie utworznenia, dacie ważności konta klienta w formacie RRRR-MM-DD, imieniu, nazwisku, emial użytkownika, dacie ważności licencji w formacie RRRR-MM-DD, metodyce, teście.",
  },
  Snackbars: {
    createExamineeSuccess: "Pomyślne dodano badanego",
    editExamineeSuccess: "Pomyślne edytowano badanego",
    createPsychLabSuccess: "Pomyślne dodano konto klienta",
    editPsychLabSuccess: "Pomyślne edytowano konto klienta",
    failedRequest: "Wystąpił problem: ",
    movingExamineesSuccess: "Przeniesienie do innego folderu powiodło się",
    passwordChangeSuccess: "Zmiana hasła powiodła się",
    pinChangeSuccess: "Zmiana PINu powiodła się",
    success: "Akcja powiodła się",
    unrecognizedError: "Nierozpoznany błąd, sprawdź połączenie z internetem",
    createExaminationSuccess: "Poprawnie przydzielono badanie",
    deleteExaminationSuccess: "Poprawnie usunięto badanie",
    editPsychUserSuccess: "Pomyślne edytowano użytkownika",
    createPsychUserSuccess: "Pomyślne dodano użytkownika",
    createPsychLicSuccess: "Pomyślne dodano licencję",
    editPsychLicSuccess: "Pomyślne edytowano licencję",
  },
  UserSettings: {
    header: "Ustawienia konta",
    headerChangePassword: "Zmiana hasła",
    headerChangePin: "Zmiana PIN",
    setNewPin: "Ustaw nowy PIN",
    pinInfo: "Wprowadź PIN jaki będziesz wykorzystywać do logowania do konsolety psychologa.",
    headerMobile: "Ustawienia",
    facilityInfo: "Dane pracowni",
    userInfo: "Dane użytkownika",
    passwordChange: "Zmiana hasła",
    passwordExpiry: "Twoje hasło wygasa: ",
    pinChange: "Zmiana numeru pin",
    infoSubHeaders: {
      labName: "Nazwa",
      address: "Adres",
      cin: "Numer wpisu do rejestru",
      tin: "NIP",
      firstName: "Imię",
      lastName: "Nazwisko",
      email: "Email",
      dateExpire: "Data ważności konta",
      licenseNumber: "Numer licencji"
    },
    helpdeskMsgUser: "Jeśli chcesz zmienić dane pracowni lub użytkownika to zadzwoń na numer:",
    helpdeskMsgLab: "Jeśli chcesz zmienić dane pracowni lub użytkownika to zadzwoń na numer:",
  },
  Backend: {
    User_activation_code_has_already_been_used: "Kod aktywacyjny został już użyty.",
    User_activation_code_does_not_exist: "Podany kod aktywacyjny nie istnieje.",
    User_with_given_id_does_not_exist: "Użytkownik z danym id nie istnieje.",
    Token_not_recognized: "Token nie został rozpoznany.",
    Unrecognized_token_Please_regenerate_password_reset_link: "Nierozpoznany token, proszę wygenerować nowy link do resetowania hasła.",
    Invalid_token_Please_regenerate_password_reset_link: "Nieprawidłowy token, proszę wygenerować nowy link do resetowania hasła.",
    Validation_failed: "Walidacja wprowadzonych danych nieudana.",
    Could_not_bind_users_PsychologicalLab_with_the_examinee: "Nie udało się powiązać placówki użytkownika z badanym.",
    Could_not_bind_users_PsychologicalLab_with_the_directory: "Nie udało się powiązać placówki użytkownika z folderem.",
    The_password_cannot_be_the_same_as_the_previous_one: "Hasło nie może być takie samo jak poprzednie.",
    Incorrect_credentials: "Błędne hasło.",
    Unrecognized_email: "Adres email nie został rozpoznany lub konto nie zostało aktywowane.",
    Directory_has_related_Examinees: "Brak możliwości usunięcia Folderu: Folder ma powiązanych badanych.",
    Unable_to_delete_default_directory: "Brak możliwości usunięcia Folderu Domyślnego.",
    The_new_pin_cannot_be_the_same_as_the_previous_one: "Nowy PIN nie może być taki sam jak poprzedni.",
    Directory_with_given_name_already_exists: "Folder z daną nazwą już istnieje.",
    Examination_Card_does_not_have_a_defined_NormSet: "Karta badania nie posiada przypisanej normy badania.",
    Examinee_with_the_given_id_exists_in_this_psychological_lab: "Badany o podanym numerze PESEL już istnieje w bazie.",
    The_Examinee_has_related_Examinations: "Brak możliwości usunięcia Badanego: Badany ma powiązane badania.",
    Unrecognized_proxy_error_occurred_while_fetching_legacy_data: "Nierozpoznany błąd pobierania danych archiwalnych.",
    Legacy_data_instance_not_found: "Nie znaleziono danych archiwalnych.",
    Legacy_data_validation_failed: "Błąd walidacji danych archiwalnych.",
    Unable_to_parse_Examination_Aim: "Błąd walidacji Celu Badania.",
    Unable_to_parse_Occupational_Medicine_Basic_Info_data: "Błąd walicaji Przebiegu Badania - Medycyna Pracy",
    Ongoing_examination: "Brak możliwości usunięcia Badnia. Badanie w toku.",
  },
  Onboarding: {
    errorText: "Podczas ustawiania hasła nastąpił błąd, spróbuj ponownie później.",

    successTitle: "Hasło oraz PIN ustawione",
    success: "Twoje hasło oraz PIN zostały zapisane. Możesz teraz pierwszy raz zalogować się do aplikacji używając swojego adresu email oraz hasła.",
    setNewPasswordInfo: "Wprowadź hasło jakie będziesz wykorzystywać do logowania do systemu.",
    setPasswordFormLabel: "Ustaw hasło",
    setPinInfo: "Wprowadź PIN jaki będziesz wykorzystywać do logowania do konsolety psychologa.",
    setPinFormLabel: "Ustaw swój PIN",
  },
  ResetPassword: {
    formHeader: "Resetuj Hasło",
    resetInfo: "Wprowadź adres email swojego konta, aby otrzymać link do resetowania hasła.",
    resetEmailSentPt1: "Na adres e-mail: ",
    resetEmailSentPt2: " została wysłana wiadomość zawierająca instrukcje dotyczące procesu resetowania hasła.",
    errorText: "Podczas resetowania hasła nastąpił błąd, spróbuj ponownie później.",
    successTitle: "Zapisano Hasło",
    success: "Twoje hasło zostało zmienione. Możesz teraz zalogować się do aplikacji używając swojego adresu email oraz nowego hasła.",
    setNewPassword: "Ustaw nowe hasło",
    setNewPasswordInfo: "Wprowadź hasło jakie będziesz wykorzystywać do logowania do systemu.",
  },
  FormLabels: {
    email: "E-mail",
    password: "Hasło",
    currentPassword: "Obecne hasło",
    newPassword: "Nowe hasło",
    pin: "PIN",
  },
  FormValidationErrors: {
    required: "To pole jest wymagane",
    email: "Wprowadzona wartość nie jest adresem email",
  },
  Login: {
    header: "LOGOWANIE",
    adminHeader: "PANEL ADMINISTRACYJNY",
    error: "Błędny e-mail lub hasło.",
    warning: "CAPS LOCK jest włączony",
    reset: "Zresetuj hasło",
    Instance_configured_incorrectly: "Błąd konfiguracji systemu. Zgłoś problem pomocy technicznej.",
    No_recaptcha_token_provided: "Błąd recaptcha. Zgłoś problem pomocy technicznej.",
    Validation_failed: "Błędny e-mail lub hasło.",
    Laboratory_is_inactive_or_deleted: "Nieaktywne konto klienta",
    User_account_is_not_active: "Nieaktywne konto użytkownika",
    User_account_has_been_deleted: "Konto użytkownika zostało usunięte",
    Captcha_error: "Błąd wypełaniania captcha. Spróbuj ponownie.",
    Access_denied: "Odmowa dostępu"
  },
  Directory: {
    defaultFolderName: "Folder domyślny",
    header: "Karoteka badanych",
    popup: {
      create: "Podaj nazwę folderu",
      edit: "Zmień nazwę folderu",
    },
    emptyState: {
      header: "Brak badanych w wybranym folderze",
      info: "Aby zobaczyć listę badanych w folderze dodaj do niego przynajmniej jednego badanego",
    },
    labels: {
      name: "Nazwa folderu",
      owner: "Właściciel",
      examineeCount: "Osoby",
    },
    kebabMenu: {
      addExaminee: "Dodaj badanego",
      addDirectory: "Dodaj folder",
      edit: "Edytuj nazwę folderu",
      delete: "Usuń folder",
    },
    popups: {
      delete: {
        confirmationHeader: "Czy na pewno chcesz usunąć folder?",
        confirmationText: "Po usunięciu folderu nie będzie możliwości przywrócenia go.",
      },
    },
  },
  DirectoryPreview: {
    headerPopup: "Przenieś badanych",
    labels: {
      firstName: "Imię",
      lastName: "Nazwisko",
      peselSlashIdentifier: "PESEL / Identyfikator",
      lastModification: "Ostatnia aktywność",
    },
    kebabMenu: {
      edit: "Edytuj dane badanego",
      move: "Przenieś badanego",
      delete: "Usuń badanego",
    },
    breadcrumbs: "Kartoteka badanych",
  },
  Pagination: {
    outOf: "z",
  },
  PasswordExpired: {
    popup: {
      header: "Upłynęła ważność hasła",
      text: "W celu ustawienia nowego hasła zostaniesz przeniesiony do procesu ustawienia nowego hasła.",
    },
    form: {
      header: "Ustaw nowe hasło",
      text: "Wprowadź hasło jakie będziesz wykorzystywać do logowania do systemu.",
    },
    success: {
      header: "Zapisano hasło",
      text: "Twoje hasło zostało zmienione. Możesz teraz przejść do aplikacji.",
    },
  },
  DocumentationManagement: {
    snackbar: {
      save: "Pomyślnie zapisano dokument",
    },
    names: {
      ExaminationCard: "Karta badania",
      ExaminationCardTEST2WORK: "Karta badania TEST2WORK",
      ExaminationCardTEST2SAFE: "Karta badania TEST2SAFE",
      ExaminationCardTEST2ARMS: "Karta badania TEST2ARMS",
      ExaminationCardTEST2DRIVE: "Karta badania TEST2DRIVE",
      PsychologicalConsultation: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2WORK: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2SAFE: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2ARMS: "Konsultacja psychologiczna",
      PsychologicalConsultationTEST2DRIVE: "Konsultacja psychologiczna",
      PsychologicalOpinion: "Opinia psychologiczna",
      PsychologicalOpinionTEST2WORK: "Opinia psychologiczna",
      PsychologicalOpinionTEST2SAFE: "Opinia psychologiczna - detektywi",
      PsychologicalOpinionTEST2ARMS: "Opinia psychologiczna",
      PsychologicalOpinionTEST2DRIVE: "Opinia psychologiczna",
      CivilExplosivesJudgment: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2WORK: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2SAFE: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2ARMS: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      CivilExplosivesJudgmentTEST2DRIVE: "Orzeczenie broń i materiały wybuchowe na użytek cywilny",
      DriverJudgment: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2WORK: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2SAFE: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2ARMS: "Orzeczenie kierowanie pojazdami",
      DriverJudgmentTEST2DRIVE: "Orzeczenie kierowanie pojazdami",
      DrivingExaminerJudgment: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2WORK: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2SAFE: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2ARMS: "Orzeczenie egzaminator jazdy",
      DrivingExaminerJudgmentTEST2DRIVE: "Orzeczenie egzaminator jazdy",
      DrivingInstructorJudgment: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2WORK: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2SAFE: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2ARMS: "Orzeczenie instruktor jazdy",
      DrivingInstructorJudgmentTEST2DRIVE: "Orzeczenie instruktor jazdy",
      DrivingTechniqueInstructorJudgment: "Orzeczenie instruktor techniki jazdy",
      EmergencyVehicleDriverJudgment: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2WORK: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2SAFE: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2ARMS: "Orzeczenie pojazdy uprzywilejowane",
      EmergencyVehicleDriverJudgmentTEST2DRIVE: "Orzeczenie pojazdy uprzywilejowane",
      FirearmLicenseJudgment: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2WORK: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2SAFE: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2ARMS: "Orzeczenie pozwolenie na broń",
      FirearmLicenseJudgmentTEST2DRIVE: "Orzeczenie pozwolenie na broń",
      MilitaryExplosivesJudgment: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2WORK: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2SAFE: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2ARMS: "Orzeczenie broń i materiały policyjne lub wojskowe",
      MilitaryExplosivesJudgmentTEST2DRIVE: "Orzeczenie broń i materiały policyjne lub wojskowe",
      OccupationalMedicineJudgmentTEST2WORK: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2SAFE: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2ARMS: "Orzeczenie medycyna pracy",
      OccupationalMedicineJudgmentTEST2DRIVE: "Orzeczenie medycyna pracy",
      PhysicalProtectionJudgment: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2WORK: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2SAFE: "Orzeczenie - pracownik ochrony fizycznej",
      PhysicalProtectionJudgmentTEST2ARMS: "Orzeczenie licencja ochroniarza",
      PhysicalProtectionJudgmentTEST2DRIVE: "Orzeczenie licencja ochroniarza",
      ProfessionalDriverJudgment: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2WORK: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2SAFE: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2ARMS: "Orzeczenie stanowisko kierowcy",
      ProfessionalDriverJudgmentTEST2DRIVE: "Orzeczenie stanowisko kierowcy",
    },
    emptyState: {
      header: "Badanie nie zostało zakończone",
    },
    report: {
      details: {
        assignDate: "Data przydzielenia badania",
        endDate: "Data zakończenia badania",
        firstName: "Imię",

        PESEL: "PESEL",
        identificationNumber: "Identyfikator",
        idExamination: "ID badania",
        lastName: "Nazwisko",
        name: "Nazwa badania",
        status: "Status badania",
      },
      statusExamination: {
        skip: "Pominięto lub przerwano niektóre testy",
      },
      header: {
        language: "Wybierz język raportu",
        standard: "Wybierz normę",
        recipient: "Wybierz odbiorcę",
        interval: "Wybierz przedział ufności",
        reportInterpretation: "Raport interpretacyjny",
        reportDiagnostic: "Raport diagnostyczny",
      },
    },
    consultation: {
      breadcrumbs: "Konsultacja psychologiczna",
      examineeData: "Dane badanego",
      statement: "stwierdzam",
    },
    opinion: {
      breadcrumbs: "Opinia psychologiczna",
      breadcrumbsAppeal: "Opinia psychologiczna odwoławcza",
      examineeData: "Dane badanego",
      statement: "NA PODSTAWIE PRZEPROWADZONEGO BADANIA PSYCHOLOGICZNEGO STWIERDZAM",
    },
    judgment: {
      isAppealTitle: "ORZECZENIE ODWOŁAWCZE",
      appeal: "Od niniejszego orzeczenia służy prawo odwołania się do",
      appealDate: "W terminie",
      appealDay: "W terminie",
      appealDay2: "dni od dnia jego otrzymania",
      appealDayTlt: "Liczba dni",
      drivingLicense: "W zakresie prawa jazdy kategorii",
      judgmentNumber: "Orzeczenie psychologiczne nr",
      judgmentNumberAppeal: "Orzeczenie psychologiczne nr",
      legalBais: "Podstawa prawna badania",
      statement: "Stwierdzam*",
      occupationalMedicineStatement: "Orzekam*",
      statementThat: "Stwierdzam, że",
      nextLegalBasis: "Podstawa prawna następnego badania:",
      remarks: "Uwagi",
      ART_1: "art. 39k ust. 3 pkt 1",
      ART_2: "art. 39k ust. 3 pkt 2",
      examiner_ART_1: "art. 34 ust. 5 pkt 1",
      examiner_ART_2: "art. 34 ust. 5 pkt 2",
      header: {
        base: "Orzeczenie",
        baseAppeal: "Orzeczenie odwoławcze",
        baseAppealFooter: "Niniejsze orzeczenie jest ostateczne.",
        civilExplosives: "Działalność koncesjonowana - do użytku cywilnego",
        civilExplosivesAppeal: "Działalność koncesjonowana - do użytku cywilnego - odwołanie",
        driver: "Kierowanie pojazdami",
        emergencyVehicleDriver: "Kierowca pojazdu uprzywilejowanego",
        firearmLicense: "Pozwolenie na broń",
        militaryExplosives: "Działalność koncesjonowana - o przeznaczeniu wojskowym lub policyjnym",
        physicalProtection: "Pracownik ochrony",
        profesionalDriver: "Stanowisko kierowcy",
        drivingInstructor: "Instruktor",
        drivingExaminer: "Egzaminator",
        occupationalMedicine: "Medycyna pracy",
      },
      emergencyVehicleDriver: {
        true: "brak przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym wartości pieniężne",
        false: "istnienie przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym wartości pieniężne",
      },
      drivingVehicles: {
        true: "brak przeciwwskazań psychologicznych kierowania pojazdami",
        false: "istnienie przeciwwskazań psychologicznych kierowania pojazdami",
      },
      physicalProtection: {
        true: "posiada zdolność do wykonywania zadań pracownika ochrony fizycznej",
        false: "nie posiada zdolności do wykonywania zadań pracownika ochrony fizycznej",
      },
      civilExplosives: {
        true: "nie wykazuje zaburzeń funkcjonowania psychologicznego, a tym samym może wykonywać działalność w zakresie nabywania oraz przechowywania materiałów wybuchowych przeznaczonych do użytku cywilnego",
        false: "wykazuje zaburzenia funkcjonowania psychologicznego, a tym samym nie może wykonywać działalności w zakresie nabywania oraz przechowywania materiałów wybuchowych przeznaczonych do użytku cywilnego",
      },
      drivingInstructor: {
        true: "brak przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
        false: "istnienie przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
      },
      drivingExaminer: {
        true: "brak przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
        false: "istnienie przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy",
      },
      militaryExplosives: {
        true: "nie wykazuje istotnych zaburzeń funkcjonowania psychologicznego, które stanowią przeszkodę do wykonywania lub kierowania działalnością gospodarczą w zakresie wytwarzania i obrotu materiałami wybuchowymi, bronią, amunicją oraz wyrobami i technologią o przeznaczeniu wojskowym lub policyjnym",
        false: "wykazuje istotne zaburzenia funkcjonowania psychologicznego, które stanowią przeszkodę do wykonywania lub kierowania działalnością gospodarczą w zakresie wytwarzania i obrotu materiałami wybuchowymi, bronią, amunicją oraz wyrobami i technologią o przeznaczeniu wojskowym lub policyjnym",
      },
      professionalDriver: {
        true: "brak przeciwwskazań psychologicznych do wykonywania pracy na stanowisku kierowcy",
        false: "istnienie przeciwwskazań psychologicznych do wykonywania pracy na stanowisku kierowcy",
      },
      firearmLicense: {
        true: "nie należy do osób wymienionych w art. 15 ust. 1 pkt 3 ustawy z dnia 21 maja 1999 r. o broni amunicji i może dysponować bronią",
        false: "należy do osób wymienionych w art. 15 ust. 1 pkt 3 ustawy z dnia 21 maja 1999 r. o broni amunicji i nie może dysponować bronią",
      },
      occupationalMedicine: {
        true: "brak przeciwwskazań psychologicznych do",
        false: "istnienie przeciwwskazań psychologicznych do",
        justification: "Uzasadnienie",
      },
    },
    ExaminationCard: {
      header: "Karta badania",
      headerTEST2WORK: "Karta badania TEST2WORK",
      headerTEST2SAFE: "Karta badania TEST2SAFE",
      headerTEST2ARMS: "Karta badania TEST2ARMS",
      headerTEST2DRIVE: "Karta badania TEST2DRIVE",
      fetchUpdated: "Aktualizuj danymi z wywiadu i wynikami",
      tooltipFetchUpdated: "Ta akcja usunie wszystkie niezapisane zmiany z Przebiegu Badania",
      tooltipFetchUpdatedDisabled: "W celu odblokowania akcji wybierz normę badania.",
      tabs: {
        examinee: "Osoba badana",
        courseOfExamination: "Przebieg badania",
        results: "Wyniki badania psychologicznego",
      },
      examinee: {
        chosenNormSet: "Norma zastosowana w badaniu",
        examinationDate: "Data Badania",
        previousExaminationDate: "Data poprzedniego badania",
        aim: "Cel badania",
        entityDirectingToExamination: "Podmiot kierujący na badanie",
        t2dAim: {
          drivingVehiclesAim: "Stwierdzenie braku lub istnienia przeciwwskazań do kierowania pojazdami.",
          professionalDriverAim: "Stwierdzenie braku lub istnienia przeciwwskazań do wykonywania pracy na stanowisku kierowcy.",
          emergencyDriverAim: "Stwierdzenie braku lub istnienia przeciwwskazań do kierowania pojazdem uprzywilejowanym lub przewożącym środki pieniężne.",
          drivingInstructorAim: "Stwierdzenie braku lub istnienia przeciwwskazań do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy.",
        },
      },
      examinationCourse: {
        forcedUpdateHeader: "Zaktualizuj Kartę Badania danymi z Wywiadu i Wynikami",
        forcedUpdateBody: "W celu odblokowania formularza wypełnij Wywiad dla Badania, wybierz normę w zakładce Osoba Badana i zaktualizuj Kartę Badania danymi z Wywiadu i Wynikami.",
        header: "Dane z wywiadu bezpośredniego i obserwacji osoby badanej",
        methodology: "Metody badania i wyniki",
        recommendations: "Zalecenia",
        conclusions: "Wnioski",
        conclusionsRecomendation: "Wnioski i zalecenia",
        careerHistory: "Przebieg pracy",
        examinationResultsHeader: "Nazwa testu i wyniki (w ramach rekomendowanej metodyki)",
        examinationExtendedResultsHeader: "Nazwa testu i wyniki dodatkowe",
        cognitiveResultsHeader: "Ocena i opis osoby badanej pod względem sprawności intelektualnej i procesów poznawczych (w ramach rekomendowanej metodyki)",
        cognitiveExtendedResultsHeader: "Ocena i opis osoby badanej pod względem sprawności intelektualnej i procesów poznawczych wynki dodatkowe",
        personalityResultsHeader: "Ocena i opis osoby badanej pod względem osobowości, z uwzględnieniem funkcjonowania w trudnych sytuacjach, a także dojrzałości społecznej (w ramach rekomendowanej metodyki)",
        personalityExtendedResultsHeader: "Ocena i opis osoby badanej pod względem osobowości, z uwzględnieniem funkcjonowania w trudnych sytuacjach, a także dojrzałości społecznej wyniki dodatkowe",
        psychomotorResultsHeader: "Sprawność psychomotoryczna (w ramach rekomendowanej metodyki)",
        psychomotorExtendedResultsHeader: "Sprawność psychomotoryczna wyniki dodatkowe",
        occupationalMedicineAdditionalInfo: "Dodatkowe informacje - Medycyna pracy",
        examinationResultsT2WHeader: "Rekomendowana metodyka",
        examinationExtendedResultsT2WHeader: "Dodatkowe",
        addExaminationExtendedResults: "Dodaj wynik dodatkowy",
        deletePopup: {
          headerText: "Czy na pewno chcesz usunąć wynik?",
          contentText: "Po usunięciu wyniku można przywtucić jego orginalną treść poprzez aktualizację danych",
          confirmationText: "Tak, chcę usunąć wynik",
        },
      },
      judgment: {
        result: "Wynik badania psychologicznego",
        sent: "Orzeczenie/kopię orzeczenia wysłano, jeżeli dotyczy",
        judgment: "Treść orzeczenia",
        justification: "Uzasadnienie orzeczenia",
        justificationText: "Treść uzasadnienia",
        recommendations: "Zalecenia",
        to: "do",
        t2d: {
          lack: "brak",
          existing: "istnieje",
          questions: {
            drivingVehiclesContraindication: "Przeciwwskazania do kierowania pojazdami.",
            professionalDriverContraindication: "Przeciwwskazania do wykonywania pracy na stanowisku kierowcy.",
            emergencyVehicleDriverContraindication: "Przeciwwskazania do kierowania pojazdem uprzywilejowanym lub przewożącym środki pieniężne.",
            drivingInstructorContraindication: "Przeciwwskazania do wykonywania czynności instruktora/egzaminatora/instruktora techniki jazdy.",
          },
        },
      },
    },
  },
};
